import { gql } from "@apollo/client";

export const getAllEvents = gql`
  query eventsS(
    $first: Int
    $skip: Int
    $orderBy: EventsOrderByInput
    $where: EventsWhereInput
  ) {
    eventsS(skip: $skip, orderBy: $orderBy, first: $first, where: $where) {
      id
      eventTitle
      eventDescription
      eventBanner {
        url
      }
      eventDateTime
    }
  }
`;

export const eventCount = gql`
  query {
    eventsSConnection {
      aggregate {
        count
      }
    }
  }
`;
