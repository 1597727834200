import Images from "../../Config/Images"

export const Workflow = () => {
    return (
        <section className="simply-work-flow">
            <div className="container">
                <div className="d-sm-flex d-block align-items-center justify-content-center">
                    <div className="quote-icon">
                        <img src={Images.iconquote} alt="" />
                    </div>
                    <div className="work-contain-right mt-sm-0 mt-4">
                        <h6 className="work-title text-sm-left text-center">OUR MOTTO</h6>
                        <h2 className="work-contain text-sm-left text-center">Simplify Workflows.</h2>
                    </div>
                </div>
            </div>
        </section>
    )
}