import { gql } from "@apollo/client";


const ProjectFields = ` id
clientName
platforms
projectBanner {
  url
}
projectPlatforms {
    id
  platform
}
projectTitle
projectDescription`
export const projects = gql`
query projectsST($first: Int, $skip: Int,   $orderBy: ProjectsOrderByInput,$where:ProjectsWhereInput) {
    projectsST( skip: $skip, orderBy: $orderBy, first: $first,where:$where) {
    ${ProjectFields}
  }
  }
  `

export const projectsSingle = gql`
query projectsSingle($where:ProjectsWhereUniqueInput!) {
    projects(where:$where) {
      ${ProjectFields}
  }
  }
  `


export const projectCat = gql`query projectCatST {
    projectCatST {
        platform
        id
  }
  }
  `

