import Images from "../../Config/Images"

export const Ourvision = () => {
    return (
        <section className="our-vision-section">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title">
                            <h2 className="common-title">Our Vision, Mission & Core Values</h2>
                        </div>
                    </div>
                </div>
                <div className="row vision-row">
                    <div className="col-md-6">
                        <div className="vision-box our-mission">
                            <h5 className="vision-title">Our Mission</h5>
                            <div className="d-flex align-items-start pb-3">
                                <h2 className="vision-box-title">1</h2>
                                <p className="vision-contain">Knowledge sharing on upcoming technologies by digital presence</p>
                            </div>
                            <div className="d-flex align-items-start">
                                <h2 className="vision-box-title">2</h2>
                                <p className="vision-contain">Grow businesses through power of digital transformation</p>
                            </div>
                        </div>
                        <div className="vision-box our-vision">
                            <h5 className="vision-title">Our Vision</h5>
                            <p className="vision-contain">To become well recognised team by transforming & scaling businesses
                            worldwide</p>
                        </div>
                    </div>
                    <div className="col-md-6 mt-md-0 mt-5">
                        <div className="vision-box core-value">
                            <h5 className="vision-title">Core Values</h5>
                            <ul className="core-value-list">
                                <li className="d-flex align-items-center">
                                    <div className="core-contain-img">
                                        <img src={Images.badge} alt="" />
                                    </div>
                                    <p className="core-value-contain">Quality</p>
                                </li>
                                <li className="d-flex align-items-center">
                                    <div className="core-contain-img">
                                        <img src={Images.satisfaction} alt="" />
                                    </div>
                                    <p className="core-value-contain">Satisfaction</p>
                                </li>
                                <li className="d-flex align-items-center">
                                    <div className="core-contain-img">
                                        <img src={Images.technology} alt="" />
                                    </div>
                                    <p className="core-value-contain">Technology</p>
                                </li>
                                <li className="d-flex align-items-center">
                                    <div className="core-contain-img">
                                        <img src={Images.team} alt="" />
                                    </div>
                                    <p className="core-value-contain">Carrying</p>
                                </li>
                                <li className="d-flex align-items-center">
                                    <div className="core-contain-img">
                                        <img src={Images.response} alt="" />
                                    </div>
                                    <p className="core-value-contain">Responsibility</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}