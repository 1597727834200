import { useEffect, useState } from "react"
import Images from "../Config/Images"
import { useHistory } from "react-router-dom"
import { useQuery, useLazyQuery } from "@apollo/client"
import { allBlogs, allBlogsCategories } from "../queries/blog"

export const Blog = () => {
    const history = useHistory()
    const [Activetab, _Activetab] = useState(1)
    const [where, _where] = useState({})
    const [page, _page] = useState(5)
    const [blogList, _blogList] = useState([])
    const [showpagination, _showPagination] = useState(false)
    const { data: blogsCategoriesdata } = useQuery(allBlogsCategories)
    const [getBlog, { data: blogsData }] = useLazyQuery(allBlogs)

    useEffect(() => {
        if (Activetab != 1)
            _where({ "blogsCategoriesST_every": { "id": Activetab } })
        else
            _where({})
    }, [Activetab])

    useEffect(() => {
        _blogList([])
        _page(5)
        getBlog({ variables: { "first": 5, "skip": 0, "where": where, "orderBy": null } })
    }, [where])

    useEffect(() => {
        if (blogsData?.blogArticlesST?.length > 4)
            _showPagination(true)
        else
            _showPagination(false)
        if (blogsData?.blogArticlesST?.length) {
            if (!blogList?.length) {
                _blogList(blogsData?.blogArticlesST)
            }
            else {
                _blogList([...blogList, ...blogsData?.blogArticlesST])
            }
        }
        else {
            _page(page - 5)
        }
    }, [blogsData])

    const LoadMore = async () => {
        _page(page + 5)
        await getBlog({ variables: { "first": page + 5, "skip": page, "where": where, "orderBy": null } })
    }
    return (
        <>
            <section className="services-banner">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 pr-lg-5">
                            <div className="banner-content">
                                <h1 className="banner-title">Blogs</h1>
                                <p className="banner-contain">Lorem Ipsum is simply dummy text of the printing and typesetting
                                    industry. Lorem Ipsum has been the industry’s standard dummy text ever</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <img className="mx-auto d-block" src={Images.blogbanner} alt="img" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="imgaing-industries">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-12 mx-auto">
                            <div className="section-title">
                                <h2 className="common-title">Blogs & Articles</h2>
                            </div>
                        </div>
                    </div>
                    <div className="our-work-portfolio">
                        <ul className="nav nav-pills our-wrok-list justify-content-center" id="pills-tab" role="tablist">
                            <li className="nav-item" onClick={(e) => { _Activetab(1); e.preventDefault(); }}>
                                <a className={`nav-link ${Activetab == 1 ? 'active' : ""}`}>All</a>
                            </li>
                            {blogsCategoriesdata?.blogsCategoriesST?.map(category => {
                                return (
                                    <li className="nav-item" onClick={(e) => { _Activetab(category?.id); e.preventDefault(); }}>
                                        <a className={`nav-link ${Activetab == category?.id ? 'active' : ""}`}>{category.categoryName}</a>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    <div className="potfolio-data">
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel"
                                aria-labelledby="pills-home-tab">
                                <div className="row">
                                    {blogList?.map((article) => {
                                        return (
                                            <div className="col-lg-4 col-md-6 col-sm-12 blogs">
                                                <div className="blog">
                                                    <a onClick={() => { console.log('items', article); history.push(`blog-descrption/${article?.articleTitle}`, { article }) }}>
                                                        <div className="blog-img">
                                                            <img src={article?.articleBanner?.url} width='365px' height='250px' />
                                                        </div>
                                                        <div className="blog-details" >
                                                            <h3 className="blog-text">{article?.articleTitle}</h3>
                                                            <div className="day">
                                                                <h5 className="days">{article?.days}</h5>
                                                                <p className="read">{article?.read}</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    {showpagination ?
                                        <div className="col-md-12 col-sm-12 text-center">
                                            <button className="btn btn-red" onClick={() => LoadMore()} >See More</button>
                                        </div>
                                        : <></>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}