import { gql } from "@apollo/client";

export const getAchievements = gql`
  query {
    homeDataST {
      id
      achievementDesc
      achievementTitle
      type
      iconPath {
        url
      }
    }
  }
`;
