import React, { useState } from 'react';

export const Blogdescrption = (props) => {

    const { article } = props?.location?.state;
    const [blog] = useState(article)
    return (
        <>
            <section className="pb-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto">
                            <div>
                                <h4 className="conculation-title">{blog?.articleTitle}</h4>
                                <p className="concultion-sub-title">{blog?.days}</p>
                            </div>
                            <div className="concultion-img-data">
                                {/* <a href="#"> */}
                                <div className="concultion-img"><img src={blog?.articleBanner?.url} alt='' width='1028px' height='450' /></div>
                                {/* </a> */}
                                <p className="concultion-common-contain">{blog?.articleSubDescription}</p>
                            </div>
                            <div className="concultion-common">
                                <h3 className="banner-title">{blog?.articleTitle}</h3>
                                <p className="concultion-common-contain">{blog?.articleDescription?.text}</p>
                                {/* <p className="concultion-common-contain">Lorem Ipsum is simply dummy text of the printing and
                                typesetting industry. Lorem Ipsum has
                                been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a
                            galley of type and scrambled it to make a type specimen book.</p> */}
                            </div>
                            <div className="concultion-common">
                                <h3 className="banner-title">Conclusion</h3>
                                <p className="concultion-common-contain">{blog?.articleSubDescription}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}