import { useLazyQuery } from "@apollo/client"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import Images from "../Config/Images"
import { projects, projectsSingle } from "../queries/ourWork"

const Platform = [
    {
        name: "client",
        platform: "Lorem ipsum"
    },
    {
        name: "client",
        platform: "Lorem ipsum"
    },
    {
        name: "client",
        platform: "Mobile,Web"
    }
]

const ContactSuccess = [
    {
        src: Images.goal,
        title: 'Hours 1-5',
        data: 'We understand your requirements, your budget, your goals and your business characteristics.'
    },
    {
        src: Images.implementation,
        title: 'Hours 5-20',
        data: 'We employ teams to get personalized solutions, create a master delivery plan, and contact vendors.'
    },
    {
        src: Images.goal,
        title: 'Hours 20-30',
        data: 'We validate the master plan with you, remodel it if needed and start working on customization, configuration and delivery.'
    }
]

export const WorkDetail = (props) => {
    const [item, _item] = useState({})
    const { id } = useParams();
    const [getProject, { data: ProjectData }] = useLazyQuery(projectsSingle)

    useEffect(async () => {
        let where = { id }
        if (id != '') {
            await getProject({ variables: { "where": where } })
        }

    }, [id])

    useEffect(() => {
        _item(ProjectData?.projects)
    }, [ProjectData])
    return (
        <>
            <section className="services-banner">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 pr-lg-5">
                            <div className="banner-content">
                                <h1 className="banner-title">{item?.projectTitle}</h1>
                                <p className="banner-contain">{item?.projectDescription}</p>
                                <div className="client-platform">

                                    <div className="client-platform-data">
                                        <h5 className="client-platform-title">CLIENT</h5>
                                        <p className="client-platform-contain">{item?.clientName}</p>
                                    </div>
                                    <div className="client-platform-data">
                                        <h5 className="client-platform-title">PROJECT</h5>
                                        <p className="client-platform-contain">{item?.projectTitle}</p>
                                    </div>
                                    <div className="client-platform-data">
                                        <h5 className="client-platform-title">PLATFORM</h5>
                                        <p className="client-platform-contain">{item?.platforms}</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-lg-0 mt-4">
                            <div className="our-work-box pb-0">
                                <div className="portfolio-img trucking-box">
                                    <h3 className="our-box-title">{item?.projectTitle}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="trucking-sub-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9">
                            <h2 className="sub-banner-title">First 30 Hours From First Contact Define Your Business Success</h2>
                        </div>
                    </div>
                    <div className="row hours-row">

                        {ContactSuccess.map((items, index) => {
                            return (
                                <div className="col-md-4 col-sm-6 mb-4 hours-col">
                                    <div className="hours-box">
                                        <img className={`hours-img ${index == 0 ? "blue-img" : index == 1 ? "yellow-img" : 'red-img'}`} src={items.src} alt="" />
                                        <h3 className="hours-title">{items.title}</h3>
                                        <p className="hours-contain">{items.data}</p>
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                </div>
            </section>
            <section className="challenge-section mb-5">
                <div className="container">
                    <div className="row align-items-center position-relative m-0">
                        <div className="spy-left-option col-lg-6 col-md-8 m-lg-0 mb-4 mx-auto">
                            <div className="list-group justify-content-center flex-lg-row-reverse flex-row scroll-spy"
                                id="list-example">
                                <a className="list-group-item list-group-item-action" href="#brief">Brief</a>
                                <a className="list-group-item list-group-item-action" href="#discover">Discover</a>
                                <a className="list-group-item list-group-item-action" href="#define">Define</a>
                                <a className="list-group-item list-group-item-action" href="#outcome">The Outcome</a>
                            </div>
                        </div>
                        <div data-spy="scroll" data-target="#list-example" data-offset="0"
                            className="scrollspy-example col-lg-11 col-12 ml-auto">
                            <div className="row align-items-center pb-5" id="brief">
                                <div className="col-lg-6 pr-lg-4 mb-lg-0 mb-4">
                                    <div className="challenge-box">
                                        <h3 className="challange-title">The Challenge for us</h3>
                                        <p className="challange-contain">Lorem Ipsum is simply dummy text of the printing
                                        and typesetting industry. Lorem Ipsum has been the industry’s standard dummy
                                        text ever since the 1500s, when an unknown printer took a galley of type and
                                            scrambled it to make a type specimen book.</p>
                                        <p className="challange-contain p-0">Lorem Ipsum is simply dummy text of the
                                        printing and typesetting industry. Lorem Ipsum has been the industry’s
                                        standard dummy text ever since the 1500s, when an unknown printer took a
                                            galley of type and scrambled it to make a type specimen book.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 pl-lg-4">
                                    <div className="challange-right-data">
                                        <div className="challenge-task-box blue-line d-inline-flex align-items-center ">
                                            <h4 className="challenge-task-title blue-title">01</h4>
                                            <h4 className="challenge-task-contain">Lorem ipsum simply</h4>
                                        </div>
                                        <div className="challenge-task-box pink-line d-inline-flex align-items-center ">
                                            <h4 className="challenge-task-title pink-title">02</h4>
                                            <h4 className="challenge-task-contain">lorem ipsum dollar sit simple book at
                                            </h4>
                                        </div>
                                        <div className="challenge-task-box yellow-line d-inline-flex align-items-center  mb-0">
                                            <h4 className="challenge-task-title yellow-title">03</h4>
                                            <h4 className="challenge-task-contain">Lorem ipsum lorem ipsum</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center pb-5 flex-lg-row flex-column-reverse" id="discover">
                                <div className="col-lg-6 pl-lg-4 mt-lg-0 mt-4">
                                    <div className="challange-right-data">
                                        <div className="challenge-task-box blue-line d-inline-flex align-items-center ">
                                            <h4 className="challenge-task-title blue-title">01</h4>
                                            <h4 className="challenge-task-contain">Lorem ipsum simply</h4>
                                        </div>
                                        <div className="challenge-task-box pink-line d-inline-flex align-items-center ">
                                            <h4 className="challenge-task-title pink-title">02</h4>
                                            <h4 className="challenge-task-contain">lorem ipsum dollar sit simple book at
                                            </h4>
                                        </div>
                                        <div className="challenge-task-box yellow-line d-inline-flex align-items-center mb-0">
                                            <h4 className="challenge-task-title yellow-title">03</h4>
                                            <h4 className="challenge-task-contain">Lorem ipsum lorem ipsum</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 pr-lg-4">
                                    <div className="challenge-box">
                                        <h3 className="challange-title">Objectives & Problem Statements</h3>
                                        <p className="challange-contain">Lorem Ipsum is simply dummy text of the printing
                                        and typesetting industry. Lorem Ipsum has been the industry’s standard dummy
                                        text ever since the 1500s, when an unknown printer took a galley of type and
                                            scrambled it to make a type specimen book.</p>
                                        <p className="challange-contain p-0">Lorem Ipsum is simply dummy text of the
                                        printing and typesetting industry. Lorem Ipsum has been the industry’s
                                        standard dummy text ever since the 1500s, when an unknown printer took a
                                            galley of type and scrambled it to make a type specimen book.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center pb-5" id="define">
                                <div className="col-lg-6 pr-lg-4 mb-lg-0 mb-4">
                                    <div className="challenge-box">
                                        <h3 className="challange-title">The Action Plan</h3>
                                        <p className="challange-contain">Lorem Ipsum is simply dummy text of the printing
                                        and typesetting industry. Lorem Ipsum has been the industry’s standard dummy
                                        text ever since the 1500s, when an unknown printer took a galley of type and
                                            scrambled it to make a type specimen book.</p>
                                        <p className="challange-contain p-0">Lorem Ipsum is simply dummy text of the
                                        printing and typesetting industry. Lorem Ipsum has been the industry’s
                                        standard dummy text ever since the 1500s, when an unknown printer took a
                                            galley of type and scrambled it to make a type specimen book.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 pl-lg-4">
                                    <div className="challange-right-data">
                                        <div className="challenge-task-box blue-line d-inline-flex align-items-center">
                                            <h4 className="challenge-task-title blue-title">01</h4>
                                            <h4 className="challenge-task-contain">Lorem ipsum simply</h4>
                                        </div>
                                        <div className="challenge-task-box pink-line d-inline-flex align-items-center">
                                            <h4 className="challenge-task-title pink-title">02</h4>
                                            <h4 className="challenge-task-contain">lorem ipsum dollar sit simple book at
                                            </h4>
                                        </div>
                                        <div className="challenge-task-box yellow-line d-inline-flex align-items-center mb-0">
                                            <h4 className="challenge-task-title yellow-title">03</h4>
                                            <h4 className="challenge-task-contain">Lorem ipsum lorem ipsum</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center flex-lg-row flex-column-reverse" id="outcome">
                                <div className="col-lg-6 pl-lg-4 mt-lg-0 mt-4">
                                    <div className="challange-right-data">
                                        <div className="challenge-task-box blue-line d-inline-flex align-items-center">
                                            <h4 className="challenge-task-title blue-title">01</h4>
                                            <h4 className="challenge-task-contain">Lorem ipsum simply</h4>
                                        </div>
                                        <div className="challenge-task-box pink-line d-inline-flex align-items-center">
                                            <h4 className="challenge-task-title pink-title">02</h4>
                                            <h4 className="challenge-task-contain">lorem ipsum dollar sit simple book at
                                            </h4>
                                        </div>
                                        <div className="challenge-task-box yellow-line d-inline-flex align-items-center mb-0">
                                            <h4 className="challenge-task-title yellow-title">03</h4>
                                            <h4 className="challenge-task-contain">Lorem ipsum lorem ipsum</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 pr-lg-4">
                                    <div className="challenge-box">
                                        <h3 className="challange-title">The Outcome</h3>
                                        <p className="challange-contain">Lorem Ipsum is simply dummy text of the printing
                                        and typesetting industry. Lorem Ipsum has been the industry’s standard dummy
                                        text ever since the 1500s, when an unknown printer took a galley of type and
                                            scrambled it to make a type specimen book.</p>
                                        <p className="challange-contain p-0">Lorem Ipsum is simply dummy text of the
                                        printing and typesetting industry. Lorem Ipsum has been the industry’s
                                        standard dummy text ever since the 1500s, when an unknown printer took a
                                            galley of type and scrambled it to make a type specimen book.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}