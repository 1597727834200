import { gql } from "@apollo/client";

export const allDepartments = gql`
  query {
    departmentST {
      id
      colorStyle {
        hex
      }
      department
      vancaciesST {
        id
        jobTitle
        qualifiction
        responsibility
        roleDescription
      }
    }
  }
`;

export const addWalkInRequest = gql`
  mutation ($data: WalkinRequestCreateInput!) {
    createWalkinRequest(data: $data) {
      firstName
    }
  }
`;
export const getVacancy = gql`
  query ($where: VancaciesWhereUniqueInput!) {
    vancacies(where: $where) {
      jobTitle
      qualifiction
      responsibility
      roleDescription
    }
  }
`;

export const getAllVacancies = gql`
  query {
    vancaciesST {
      id
      jobTitle
    }
  }
`
