import { useEffect, useState } from "react"
import { Ourvision } from "../Components/HomeModule/Ourvision"
import { Workflow } from "../Components/HomeModule/Workflow"
import Images from "../Config/Images"

export const AboutPage = () => {

    return (
        <>
            <section className="reform-banner">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 pr-lg-5">
                            <div className="banner-content">
                                <h1 className="banner-title">We are reformers!</h1>
                                <p className="banner-contain">Welcome to the Scaleteam - one of the fastest growing Software Development
                                company. We are IT consultant and develop customized business software applications using
                            top notch technologies.</p>
                                <div className="d-flex align-items-center">
                                    <a href="" className="play-btn" data-toggle="modal" data-target="#exampleModalCenter"><img
                                        src={Images.playbtn} alt="" /></a>
                                    <p className="watch-video">Watch the Glimpse of our 5th Anniversary</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <img src={Images.banner2} className="mx-auto d-block" alt="img" />
                        </div>
                    </div>
                </div>
            </section>
            {/* who-we-are */}
            <section className="how-we-are pt-0">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <img className="mx-auto d-block" src={Images.object} alt="" />
                        </div>
                        <div className="col-lg-6 mt-lg-0 mt-4">
                            <div className="who-we-data">
                                <h3 className="banner-title">Who we are</h3>
                                <p className="banner-contain">Scaleteam Best And Top IT Company In Surat which has skills and
                                expertise to facilitate
                            complex business solutions. We Provide Complete digital solution for your brand.</p>
                                <p className="banner-contain">Our team of Expert plan and create the most attractive website which
                                help start ups in
                                delivering complete solution because we optimize the operation which allows them to seize
                                opportunities,achieve revenue targets and deliver maximized value, we help building brands
                            online.</p>
                                <a href="#" className="common-btn d-inline-block">Learn More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* our vision */}
            <Ourvision />
            {/* simply workflow */}
            <Workflow />
            {/* scaleteam pioneer*/}
            <section className="pioneer-section pg-5">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-title">
                                <h2 className="common-title">Scaleteam’s Pioneers</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row ceo-row align-items-center pb-3 mt-3">
                        <div className="col-md-5">
                            <img src={Images.ceo} className="mx-auto d-block" alt="" />
                        </div>
                        <div className="col-md-7">
                            <div className="ceo-contain">
                                <h4 className="ceo-name">Dharmesh Khunt</h4>
                                <p className="ceo-position">Chief Executive Officer (CEO)</p>
                                <p className="company-since">Hello, I am Dharmesh Khunt, Founder and CEO of ScaleTeam Technologies
                                Private Limited. I completed my B. Tech from GCET. I started my career as a Software
                                Engineer in Lanet. With everyone’s blessings in 2016, became Founder of ScaleTeam
                            Technologies. Our Vision Is To Move Towards Emerging Tech Era..</p>
                                <a target="_blank" href="https://www.linkedin.com/in/dharmesh-khunt-a15b3653/">
                                    <img src={Images.linked} alt="" /></a>
                            </div>
                        </div>
                    </div>
                    <div className="row ceo-row flex-md-row flex-column-reverse align-items-center pb-3">
                        <div className="col-md-7">
                            <div className="ceo-contain">
                                <h4 className="ceo-name">Nitesh Mavani</h4>
                                <p className="ceo-position">Chief Technology Officer (CTO)</p>
                                <p className="company-since">Hello, I am Nitesh Mavani, Co Founder and CTO of ScaleTeam Technologies
                                Private Limited. I completed my B. Tech from BVM Engineering. I started my career as a
                                Software Engineer in Digicorp. With everyone’s blessings in 2016, became Co-Founder of
                            ScaleTeam Technologies. Our Vision Is To Move Towards Emerging Tech Era.</p>
                                <a target="_blank" href="https://www.linkedin.com/in/nitesh-mavani/?originalSubdomain=in">
                                    <img src={Images.linked} alt="" /></a>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <img src={Images.cto} className="mx-auto d-block" alt="" />
                        </div>
                    </div>
                    <div className="text-center">
                        <a href="#" className="meet-team">Meet entire team</a>
                    </div>
                </div>
            </section>
            
            {/* life scalteam */}
            {/* <section className="life-scalteam">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <p className="scaleteam-sub-title">Gallery</p>
                            <div className="section-title">
                                <h2 className="common-title">Scaleteam’s Pioneers</h2>
                            </div>
                            <a href="#" className="common-btn join-our-team text-center d-inline-block">Join Our Team</a>
                        </div>
                    </div>
                    <div className="pioneer-row row">
                        <div className="col-lg-6">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="pionner-img">
                                                <img src={Images.profile} width="100%" height="240px" alt="" />
                                            </div>
                                        </div>
                                        <div className="col-6 mt-4">
                                            <div className="pionner-img">
                                                <img src={Images.profile} width="100%" height="200px" alt="" />
                                            </div>

                                        </div>
                                        <div className="col-6 mt-4">
                                            <div className="pionner-img">
                                                <img src={Images.profile} width="100%" height="200px" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 mt-lg-0 mt-4">
                                    <div className="pionner-img h-100">
                                        <img src={Images.profile} width="100%" height="100%" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-lg-0 mt-4">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="pionner-img">
                                                <img src={Images.banner2} width="100%" height="240px" alt="" />
                                            </div>
                                        </div>
                                        <div className="col-6 mt-4">
                                            <div className="pionner-img">
                                                <img src={Images.profile} width="100%" height="200px" alt="" />
                                            </div>

                                        </div>
                                        <div className="col-6 mt-4">
                                            <div className="pionner-img">
                                                <img src={Images.profile} width="100%" height="200px" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* open video */}
            <div className="modal fade video-modal" id="exampleModalCenter" tabindex="-1" role="dialog"
                aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title d-none" id="exampleModalLongTitle">Modal title</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <iframe width="100%" height="550" src="https://www.youtube.com/embed/cvO1sZsifec"
                                title="YouTube video player" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}