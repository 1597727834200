import { Getquote } from "../Components/HomeModule/Getquote";
import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router";
import { useMutation, useQuery } from "@apollo/client";
import {
  addWalkInRequest,
  getAllVacancies,
  getVacancy,
} from "../queries/career";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import Images from "../Config/Images";
export const Careerform = () => {
  let history = useHistory();
  const [Fileupload, setFileupload] = useState({ resume: null });
  const [obj1, _obj1] = useState({
    email: null,
    lastName: null,
    firstName: null,
  });
  const [obj2, _obj2] = useState({ phoneNumber: null });
  const {
    setError,
    formState: { errors },
  } = useForm();
  const vacancyId = useParams();
  const [id, _id] = useState(vacancyId?.id);
  const { data: vacancy, refetch } = useQuery(getVacancy, {
    variables: {
      where: { id },
    },
  });
  useEffect(() => {
    refetch();
  }, [id]);
  const [createWalkInRequest] = useMutation(addWalkInRequest);
  const { data: vacanciesData } = useQuery(getAllVacancies);

  const onChangeObj1 = (event) => {
    if (id === undefined) {
      setError("role", {
        message: "Role is required",
      });
    }
    const stringData = Object.entries(obj1);
    stringData?.map((obj1) => {
      if (obj1[1] === null) {
        setError(obj1[0], {
          message: `${obj1[0].replace(
            obj1[0][0],
            obj1[0][0].toUpperCase()
          )} is Required`,
        });
      }
    });
    if (event.target.value === "" || event.target.value === null) {
      setError(event.target.name, {
        message: `${event.target.name.replace(
          event.target.name[0],
          event.target.name[0].toUpperCase()
        )} is Required`,
      });
    } else if (
      event.target.name === "email" &&
      event.target.value.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ) === null
    ) {
      setError("email", {
        message: "Email is not Valid",
      });
    } else {
      setError(event.target.name, {
        message: null,
      });
      _obj1({ ...obj1, [event.target.name]: event.target.value });
    }
  };
  const onChangeObj2 = (event) => {
    if (
      event.target.name === "phoneNumber" &&
      (event.target.value === "" || event.target.value === null)
    ) {
      setError("phoneNumber", {
        message: "PhoneNumber is Required",
      });
    } else if (
      event.target.name === "phoneNumber" &&
      event.target.value.match(/^\d{10}$/) === null
    ) {
      setError("phoneNumber", {
        message: "PhoneNumber is not Valid",
      });
    } else {
      setError(event.target.name, {
        message: null,
      });
      _obj2({ ...obj2, [event.target.name]: parseInt(event.target.value) });
    }
  };
  const onChange = (event) => {
    if (event.target.files[0] === null || event.target.files[0] === "") {
      setError("resume", {
        message: "Resume is required",
      });
    } else {
      setError("resume", {
        message: null,
      });
      const files = event.currentTarget.files;
      const file = {};
      file.fileName = files[0].name;
      file.mimeType = files[0].type;
      file.handle =
        Date.now().toString(36) + Math.random().toString(36).substr(2);
      setFileupload({ resume: { create: file } });
    }
  };
  let walkInRequest = Object.assign(obj1, obj2, Fileupload, {
    vancacies: { connect: { id: id } },
  });
  const SubmitHandler = (e) => {
    const error = Object.entries(errors);
    const errorMessage = error?.filter((error) => {
      e.preventDefault();
      if (error[1]?.message !== null) {
        swal("Error", error[1]?.message, "error");
        return error[1]?.message;
      }
    });
    if (errorMessage.length === 0) {
      if (obj1.email === null) {
        e.preventDefault();
        swal("Error", "Please enter all details", "error");
        return ;
      } else {
          createWalkInRequest({
            variables: {
              data: walkInRequest,
            },
          });
          history.push("/career");
          swal("Success", "Successfully Appilied", "success");
      }
    }
  };
  return (
    <>
      <section className="app-devloper">
        <div className="container">
          <div className="row m-0">
            <div className="col-12 mx-auto">
              <div className="section-title">
                <h2 className="common-title">
                  Lead React native Application Developer
                </h2>
              </div>
            </div>
          </div>
          <div className="row devloper-row">
            <div className="col-lg-6">
              <div className="opening-job">
                <div className="opening-">
                  <a className="all-opening" href="/career">
                    {" "}
                    See all openings{" "}
                  </a>
                  <img src={Images.rightarrow} alt="" />
                </div>
                <div className="role-data">
                  <h3 className="role-title">
                    The Role:{vacancy?.vancacies?.jobTitle}
                  </h3>
                  <p className="role-contain">
                    {vacancy?.vancacies?.roleDescription}
                  </p>
                  {/* <p className="role-contain">Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry. Lorem Ipsum has been the industry’s standard dummy text
                                    ever since the 1500s, when an unknown printer took a galley of type and
                                scrambled it to make a type specimen book.</p> */}
                </div>
                <div className="role-data">
                  <h3 className="role-title">Qualifications:</h3>
                  <p className="role-contain">
                    {vacancy?.vancacies?.qualifiction}
                  </p>
                </div>
                <div className="role-data">
                  <h3 className="role-title">Responsibility</h3>
                  <p className="role-contain">
                    {vacancy?.vancacies?.responsibility}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 pt-4">
              <form className="form contact-from" onSubmit={SubmitHandler}>
                <div className="input-form">
                  <input
                    className="contact-input"
                    type="text"
                    name="firstName"
                    onChange={onChangeObj1}
                    placeholder="First Name*"
                  />
                  {errors.firstName && (
                    <span className="contactError">
                      {errors?.firstName?.message}
                    </span>
                  )}
                </div>
                <div className="input-form">
                  <input
                    className="contact-input"
                    type="text"
                    name="lastName"
                    onChange={onChangeObj1}
                    placeholder="Last Name*"
                  />
                  {errors.lastName && (
                    <span className="contactError">
                      {errors?.lastName?.message}
                    </span>
                  )}
                </div>
                <div className="input-form">
                  <input
                    className="contact-input"
                    type="email"
                    name="email"
                    onChange={onChangeObj1}
                    placeholder="Email*"
                  />
                  {errors.email && (
                    <span className="contactError">
                      {errors?.email?.message}
                    </span>
                  )}
                </div>
                <div className="input-form">
                  <input
                    className="contact-input"
                    type="number"
                    name="phoneNumber"
                    onChange={onChangeObj2}
                    placeholder="Phone Number*"
                  />
                  {errors.phoneNumber && (
                    <span className="contactError">
                      {errors?.phoneNumber?.message}
                    </span>
                  )}
                </div>
                <div className="input-form select-form position-relative">
                  <select
                    className="contact-select"
                    name="role"
                    id="cars"
                    value={id}
                    onChange={(e) => {
                      if (e.target.value === null || e.target.name === "") {
                        setError("role", {
                          message: "Role is required",
                        });
                      } else {
                        setError("role", { message: null });
                        _id(e.target.value);
                      }
                    }}
                  >
                    <option value="" hidden selected>
                      Select your option
                    </option>
                    {vacanciesData?.vancaciesST?.map((vacancy) => {
                      return (
                        <option value={vacancy?.id}>{vacancy?.jobTitle}</option>
                      );
                    })}
                    {/* <option value="saab">Node</option>
                                        <option value="mercedes">Full stack</option>
                                        <option value="audi">MERN stack</option> */}
                  </select>
                  {errors.role && (
                    <span className="contactError">
                      {errors?.role?.message}
                    </span>
                  )}
                </div>
                <div className="input-form">
                  <input
                    className="contact-input"
                    type="text"
                    name="currentCTC"
                    onChange={onChangeObj2}
                    placeholder="Current CTC (Annual) (In Lacs)"
                  />
                </div>
                <div className="input-form">
                  <input
                    className="contact-input"
                    type="text"
                    name="expectedCTC"
                    onChange={onChangeObj2}
                    placeholder="Expected CTC (Annual) (In Lacs)"
                  />
                </div>
                <div className="input-form">
                  <div className="contact-input position-relative">
                    <label htmlFor="fileinput" className="choose-file">
                      Choose File
                    </label>
                    <input
                      className="contact-input d-none"
                      type="file"
                      name="resume"
                      placeholder=""
                      onChange={onChange}
                      accept=".pdf,.doc,application/pdf,application/msword"
                      id="fileinput"
                    />
                    <label className="choose-file-text" htmlFor="fileinput">
                      {Fileupload?.resume?.create?.fileName || "No Choose File"}
                    </label>
                  </div>
                  {errors.resume && (
                    <span className="contactError">
                      {errors?.resume?.message}
                    </span>
                  )}
                </div>
                <button className="form-submit">Apply Now</button>
              </form>
            </div>
          </div>
        </div>
      </section>
      <Getquote />
    </>
  );
};
