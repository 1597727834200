import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import {
  ApolloProvider,
  InMemoryCache,
  ApolloClient,
  HttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const httpLink = new HttpLink({
  uri: "https://api-ap-northeast-1.graphcms.com/v2/ckw3dqye560we01xw5hu37yix/master",
  useGETForQueries: true,
});

const authLink = setContext((_, { headers }) => {
  const token =
    "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE2Mzc1NTcyMTksImF1ZCI6WyJodHRwczovL2FwaS1hcC1ub3J0aGVhc3QtMS5ncmFwaGNtcy5jb20vdjIvY2t3M2RxeWU1NjB3ZTAxeHc1aHUzN3lpeC9tYXN0ZXIiLCJodHRwczovL21hbmFnZW1lbnQtbmV4dC5ncmFwaGNtcy5jb20iXSwiaXNzIjoiaHR0cHM6Ly9tYW5hZ2VtZW50LmdyYXBoY21zLmNvbS8iLCJzdWIiOiIxMjRiNWVmNi1iYjE5LTQwMDYtOTcxNC05MjRkNmI5ZjJlM2UiLCJqdGkiOiJja3dhN2FjemUxemhpMDF4cDN6YjM2dmJ2In0.U7owdaev4XdZRN4VaRke78g2oP35Rp6gZ7t8krzueTBUtBm5UXeaKTctFqWZLfw0W42KRhntfHJJ-YeKB43cFxaKKXzvB2u87MqerTZDcSmeAdu1WvzvFC5emnf_jNy-o7Ug33WmeNT6e4uVtXE_EO01wZD7Z3W7rNT_7JXCZdh7an7SFWgSSX3EwVV6veroNHkmKClo98WnJYEgKmYsQmx67dweEwPqyNZnT0QD320e2eMBVSj4YZ7kt71X-5X6vO5iOP4lmgMfB5yGSKRkXP0mApcJcgEB3ofS7_Voq5hflhRYOILGyUfyGmHlbjB5kDXjYvnstu977tFcjCR5buzvL0OOCYaJaAdsTyKxMHCMhiHSD0m9lp6pW2bVV_m070m8tqPO2JWSTZZ-aH1zTICrM_nsDp9Lxett0ekm-vfCp8EsbZ4grNB1hYON2Pwe_R-Jbr6uIRsfh4FO2XTJsIyNdPbK6LJKHQqbws9fs04t6ctTSqD65JEM8M6RGarNmRRnwwGED6mqVpQFUFC9MNMufOJnW-wVpWBnz6QTGhcukF-EZTJIVY-wMzMf_LZOvwAZRD1_RfF89iy1zXMhCAmYr6oA7JJBPVEPbTGttCRMBxitup3lBQVaebo5BiNo4LYEOiwOgCLYDgURa5DXOAfxs9c33taf0QJ74CRnum4";
  return {
    headers: {
      ...headers,
      // Authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Router>
        <App />
      </Router>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
