import { gql } from "@apollo/client";

export const getAllExpertise = gql`
  query {
    expertiseST {
      id
      logoPath {
        url
      }
      title
      color{
        hex
        }
    }
  }
`;
