import {gql} from '@apollo/client'

export const addContact = gql`
mutation($data: ContactRequestCreateInput!){
  createContactRequest(data: $data) {
    firstName
    lastName
    email
    phoneNumber
    subject
  }
}
`
