export const Getquote = () => {
    return (
        <section className="quote-section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-11 col-12 mx-auto">
                        <div className="get-quote d-flex align-items-center justify-content-between flex-md-nowrap flex-wrap">
                            <h5 className="quote-title">Let’s build great things together!</h5>
                            <a className="quote-btn" href="#">Get Quote</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}