import { useLazyQuery, useQuery } from "@apollo/client"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import Images from "../Config/Images"
import { projectCat, projects } from "../queries/ourWork"

const Allindustries = [
    {
        title: "Polynine",
        subtitle: 'Polynine',
        contain: 'Lorem Ipsum simply text - Web'
    },
    {
        title: "Axle Trucking",
        subtitle: 'Axle Trucking',
        contain: 'Lorem Ipsum simply text - Web'
    },
    {
        title: "ENLIVEN",
        subtitle: 'Enliven',
        contain: 'Lorem Ipsum simply text - Web'
    },
    {
        title: "Newkeyz",
        subtitle: 'Newkeyz',
        contain: 'Lorem Ipsum simply text - Web'
    },
    {
        title: "Imeyou",
        subtitle: 'Imeyou',
        contain: 'Lorem Ipsum simply text - Web'
    },
    {
        title: "Medixmd",
        subtitle: 'Medixmd',
        contain: 'Lorem Ipsum simply text - Web'
    },
    {
        title: "Merti",
        subtitle: 'Merti',
        contain: 'Lorem Ipsum simply text - Web'
    },
    {
        title: "Ixpress",
        subtitle: 'Ixpress',
        contain: 'Lorem Ipsum simply text - Web'
    },
    {
        title: "Green India",
        subtitle: 'Green India',
        contain: 'Lorem Ipsum simply text - Web'
    },
    {
        title: "Chat App",
        subtitle: 'Chat App',
        contain: 'Lorem Ipsum simply text - Web'
    },
]
const Meteor = [
    {
        title: "ENLIVEN",
        subtitle: 'Enliven',
        contain: 'Lorem Ipsum simply text - Web'
    },
    {
        title: "Newkeyz",
        subtitle: 'Newkeyz',
        contain: 'Lorem Ipsum simply text - Web'
    },
    {
        title: "Polynine",
        subtitle: 'Polynine',
        contain: 'Lorem Ipsum simply text - Web'
    },
    {
        title: "Axle Trucking",
        subtitle: 'Axle Trucking',
        contain: 'Lorem Ipsum simply text - Web'
    }

]
const React = [
    {
        title: "Newkeyz",
        subtitle: 'Newkeyz',
        contain: 'Lorem Ipsum simply text - Web'
    },
    {
        title: "ENLIVEN",
        subtitle: 'Enliven',
        contain: 'Lorem Ipsum simply text - Web'
    },
    {
        title: "Polynine",
        subtitle: 'Polynine',
        contain: 'Lorem Ipsum simply text - Web'
    },
    {
        title: "Axle Trucking",
        subtitle: 'Axle Trucking',
        contain: 'Lorem Ipsum simply text - Web'
    }

]
const Reactnative = [
    {
        title: "Polynine",
        subtitle: 'Polynine',
        contain: 'Lorem Ipsum simply text - Web'
    },
    {
        title: "Newkeyz",
        subtitle: 'Newkeyz',
        contain: 'Lorem Ipsum simply text - Web'
    },
    {
        title: "ENLIVEN",
        subtitle: 'Enliven',
        contain: 'Lorem Ipsum simply text - Web'
    },
    {
        title: "Axle Trucking",
        subtitle: 'Axle Trucking',
        contain: 'Lorem Ipsum simply text - Web'
    }

]
const Nodejs = [
    {
        title: "ENLIVEN",
        subtitle: 'Enliven',
        contain: 'Lorem Ipsum simply text - Web'
    },
    {
        title: "Polynine",
        subtitle: 'Polynine',
        contain: 'Lorem Ipsum simply text - Web'
    },
    {
        title: "Newkeyz",
        subtitle: 'Newkeyz',
        contain: 'Lorem Ipsum simply text - Web'
    },
    {
        title: "Axle Trucking",
        subtitle: 'Axle Trucking',
        contain: 'Lorem Ipsum simply text - Web'
    }

]
export const OurworkPage = () => {
    const history = useHistory()
    const [activeTab, _activeTab] = useState(1)
    const [tabData, _tabData] = useState([])
    const [where, _where] = useState({})
    const [page, _page] = useState(5)
    const [projectList, _projectList] = useState([])
    const [showpagination, _showPagination] = useState(false)
    const [getProject, { data: ProjectData, error: ProjectError, loading: ProjectLoading }] = useLazyQuery(projects)
    const { data: CatData, error: CatError, loading: CatLoading } = useQuery(projectCat, { variables: { "skip": 0, "where": { "AND": [] } } })

    useEffect(() => {
        if (activeTab != 1)
            _where({ projectPlatforms: { id: activeTab } })
        else
            _where({})
    }, [activeTab])

    useEffect(async () => {
        _projectList([])
        _page(5)
        await getProject({ variables: { "first": 5, "skip": 0, "where": where, "orderBy": null } })
    }, [where])

    useEffect(() => {
        if (ProjectData?.projectsST?.length > 4)
            _showPagination(true)
        else
            _showPagination(false)
        if (ProjectData?.projectsST?.length) {
            if (!projectList?.length) {
                _projectList(ProjectData?.projectsST)
            }
            else {
                _projectList([...projectList, ...ProjectData?.projectsST])
            }
        }
        else {
            _page(page - 5)
        }
    }, [ProjectData])

    const LoadMore = async () => {
        _page(page + 5)
        await getProject({ variables: { "first": page + 5, "skip": page, "where": where, "orderBy": null } })

    }
    return (
        <>
            <section className="services-banner">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 pr-lg-5">
                            <div className="banner-content">
                                <h1 className="banner-title">Our Work</h1>
                                <p className="banner-contain">With over 8+ years of our journey we have been proudly serving our clients in different industry verticals from small startups to large enterprises. Below are the references of some of our works, which makes you understand our capabilities and delivery process. Contact our team for advanced technology and crafted strategies to create conversion friendly business solutions for your business needs & to create an impactful digital presence. We analyze and deliver each project with technical finesse, innovative, creative, and precise execution tailored to the specific needs of your business.</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <img className="d-block mx-auto" src={Images.workbanner} alt="igm" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="imgaing-industries">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-12 mx-auto">
                            <div className="section-title">
                                <h2 className="common-title">Reimagining Industries</h2>
                            </div>
                        </div>
                    </div>
                    <div className="our-work-portfolio">
                        <ul className="nav nav-pills our-wrok-list justify-content-center" id="pills-tab" role="tablist">
                            <li className="nav-item" onClick={(e) => { _activeTab(1); e.preventDefault(); }}>
                                <a className={`nav-link ${activeTab == 1 ? 'active' : ""}`}>All</a>
                            </li>
                            {CatData?.projectCatST?.map((catList, index) => {
                                return (
                                    <li className="nav-item" onClick={(e) => { _activeTab(catList?.id); e.preventDefault(); }}>
                                        <a className={`nav-link ${activeTab == catList?.id ? 'active' : ""}`}>{catList?.platform}</a>
                                    </li>
                                )
                            })}
                            {/* <li className="nav-item" onClick={(e) => { _Activetab(2); e.preventDefault(); }}>
                                <a className={`nav-link ${Activtab == 2 ? 'active' : ""}`}>METEOR JS PROJECTS</a>
                            </li>
                            <li className="nav-item" onClick={(e) => { _Activetab(3); e.preventDefault(); }}>
                                <a className={`nav-link ${Activtab == 3 ? 'active' : ""}`}>REACT JS PROJECTS</a>
                            </li>
                            <li className="nav-item" onClick={(e) => { _Activetab(4); e.preventDefault(); }}>
                                <a className={`nav-link ${Activtab == 4 ? 'active' : ""}`}>REACT-NATIVE JS PROJECTS</a>
                            </li>
                            <li className="nav-item" onClick={(e) => { _Activetab(5); e.preventDefault(); }}>
                                <a className={`nav-link ${Activtab == 5 ? 'active' : ""}`}>NODE JS PROJECTS</a>
                            </li> */}
                        </ul>
                    </div>
                    <div className="potfolio-data">
                        <div className="row">
                            {projectList?.map((item) => {
                                return (
                                    <div className="col-md-6">
                                        <div className="our-work-box">
                                            <div className="portfolio-img polynine-box" 
                                            //onClick={() => { history.push(`work-detail/${item?.id}`) }}
                                            >
                                                <h3 className="our-box-title">{item?.projectTitle}</h3>
                                            </div>
                                            {/* <h5 className="work-box-sub-title">{item?.clientName}</h5> */}
                                            <p className="work-box-contain pt-2">{item?.projectDescription}</p>
                                        </div>
                                    </div>
                                )
                            })}
                            {showpagination ?
                                <div className="d-flex justify-content-center w-100 work-more-btn" onClick={() => LoadMore()}>
                                    <button className="common-btn">Show More</button>
                                </div> : <></>}

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}