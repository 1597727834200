import { useMutation, useQuery } from "@apollo/client"
import { useState } from "react"
import { Link, NavLink } from "react-router-dom"
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import swal from "sweetalert";
import { $ } from "react-jquery-plugin";
import Images from "../../Config/Images"
import { getAchievements } from "../../queries/achievements"
import { allDepartments } from '../../queries/career'
import { createHireUs } from "../../queries/hireUs";

export const Header = () => {
	let history = useHistory();
	const { data: Departments } = useQuery(allDepartments)
	const { data: Interested } = useQuery(getAchievements)
	const [model, setModel] = useState(false);
	const [clientData, setClientData] = useState({ fullName: null, email: null, phoneNumber: null, budget: null, message: null, Department: null, industry: null })
	const {
		setError,
		formState: { errors },
	} = useForm();
	const [HireUs] = useMutation(createHireUs)
	const OnChange = (event) => {
		const clientDatas = Object.entries(clientData);
		clientDatas?.map((clientData) => {
			if (clientData[1] === null) {
				setError(clientData[0], {
					message: `${clientData[0].replace(
						clientData[0][0],
						clientData[0][0].toUpperCase()
					)} is Required`,
				});
			}
		});
		if (event.target.value === null || event.target.value === '') {
			setError(event.target.name, {
				message: `${event.target.name.replace(
					event.target.name[0],
					event.target.name[0].toUpperCase()
				)} is Required`
			})
		} else if (event.target.name === 'phoneNumber' && event.target.value.match(/^\d{10}$/) === null) {
			setError("phoneNumber", {
				message: "PhoneNumber is not Valid",
			});
		}
		else if (event.target.name === 'email' && event.target.value.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) === null) {
			setError("email", {
				message: "Email is not Valid",
			});
		} else {
			setError(event.target.name, {
				message: null,
			});
			let value = event.target.value
			if (event.target.name === 'phoneNumber') {
				value = parseInt(value);
			}
			setClientData({ ...clientData, [event.target.name]: value })
		}
	}

	const submitHandler = (event) => {
		const error = Object.entries(errors)
		const errorMessage = error?.filter((error) => {
			event.preventDefault();
			if (error[1]?.message !== null) {
				swal("Error", error[1]?.message, "error");
				setModel(true)
				return error[1]?.message;
			}
		});
		if (errorMessage.length === 0) {
			if (clientData.email === null) {
				event.preventDefault();
				setModel(true)
				swal("Error", "Please enter all details", "error");
				return;
			} else {
				HireUs({
					variables: {
						data: { "email": clientData.email, "fullName": clientData.fullName, "department": { "connect": { "id": clientData.Department } }, "budget": clientData.budget, "homeData": { "connect": { "id": clientData.industry } }, "phoneNumber": clientData.phoneNumber, "message": clientData.message }
					},
				});
				swal("Success", "Successfully submitted", "success");
				setModel(false)
				$('.modal-backdrop').hide()
				history.push("/");
			}
		}
	}
	return (
		<header className="header">
			<nav className="navbar navbar-expand-lg p-0 navbar-light justify-content-between">
				<Link className="navbar-brand p-0" to="/"><img src={Images.mainLogo} alt="" /></Link>
				<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
					aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
					<span className="navbar-toggler-icon"></span>
				</button>
				<div className="collapse navbar-collapse" id="navbarSupportedContent">
					<ul className="navbar-nav mr-auto">
						<li className="nav-item">
							<NavLink activeClassName="active" className="nav-link" exact to=""> Home</NavLink>
						</li>
						<li className="nav-item">
							<NavLink activeClassName="active" className="nav-link" exact to="about"> About Us</NavLink>
						</li>
						<li className="nav-item">
							<NavLink activeClassName="active" className="nav-link" exact to="/services"> Services</NavLink>
						</li>
						<li className="nav-item">
							<NavLink activeClassName="active" className="nav-link" exact to="/our-work"> Our Work</NavLink>
						</li>
						<li className="nav-item">
							<NavLink activeClassName="active" className="nav-link" exact to="/career"> Career</NavLink>
						</li>
						{/* <li className="nav-item">
							<a activeClassName="active" className="nav-link dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" > More</a>
							<ul className="m-0 p-0 sub-menu dropdown-menu dropdown-menu-right">
								<div className="triangle-up"></div>
								<li className="d-block"><a href="/blog" className="dropdown-item">blog</a></li>
								<li className="d-block"> <a href="/champion" className="dropdown-item">champion</a></li>
								<li className="d-block"> <a href="/event" className="dropdown-item">event</a></li>
								<li className="d-block"> <a href="/culture" className="dropdown-item">cultuer</a></li>
							</ul>
						</li> */}
						<li className="nav-item" onClick={() => setModel(true)}>
							{/* <NavLink activeClassName="active" className="nav-link"  data-toggle="modal" 
                                data-target="#exampleModalCenter"> Hire Us</NavLink> */}
							<a activeClassName="active" className="nav-link" data-toggle="modal"
								data-target="#exampleModalCenter1" onClick={() => setModel(true)}> Hire Us</a>
						</li>
						{model && <div
							className="modal fade hire_home-modal"
							id="exampleModalCenter1"
							tabIndex="-1"
							role="dialog"
							aria-labelledby="exampleModalCenterTitle"
							aria-hidden="true"
						>
							<div
								className="modal-dialog modal-dialog-centered"
								role="document"
							>
								<div className="modal-content home_form-modal border-0">
									<div className="modal-body">
										<div className="d-inline-block w-100">
											<button
												type="button"
												className="close"
												data-dismiss="modal"
												aria-label="Close"
												style={{ opacity: 1 }}
											>
												<img
													src={Images.cancle}
													className="align-top"
													className="cancle-btn"
													alt=""
												/>
											</button>
										</div>
										<div className="home-modal_form">
											<div className="text-center">
												<img src={Images.mainLogo} alt="" />
												<h2 className="startup-modal-title my-3">Grow your business with us</h2>
											</div>
											<form
												action=""
												className="mt-4"
												className="row"
												onSubmit={submitHandler}
											>
												<div className="home_form mb-4 col-md-6 col-12">
													<input
														type="text"
														placeholder="Full Name*"
														name="fullName"
														className="contact-input"
														onChange={OnChange}
													/>
													{errors.fullName && (<span className="contactError">{errors?.fullName?.message}</span>)}
												</div>
												<div className="home_form mb-4 col-md-6 col-12">
													<input type="number" placeholder="Phone Number*" name="phoneNumber" className="contact-input"
														onChange={OnChange}
													/>
													{errors.phoneNumber && (<span className="contactError">{errors?.phoneNumber?.message}</span>)}
												</div>
												<div className="home_form mb-4 col-md-6 col-12">
													<input
														type="email"
														placeholder="Email*"
														name="email"
														className="contact-input"
														onChange={OnChange}
													/>
													{errors.email && (<span className="contactError">{errors?.email?.message}</span>)}
												</div>
												<div className="input-form select-form position-relative mb-4 col-md-6 col-12">
													<select className="contact-select" name="Department" id="cars"
														// value={id} 
														onChange={OnChange}
													>
														{/* <option value="" selected>Select your option</option>
                                                        {vacanciesData?.vancaciesST?.map(vacancy => {
                                                            return <option value={vacancy?.id}>{vacancy?.jobTitle}</option>
                                                        })} */}
														<option value="" hidden selected> Interested in*</option>
														{Departments?.departmentST?.map(department => {
															return (<option value={department?.id}>{department?.department}</option>)
														})}
														{/* <option value="mercedes">Web Development</option>
                                                        <option value="audi">Mobile App development</option>
                                                        <option value="audi">Digital Marketing</option>
                                                        <option value="audi">Testing & Assurance</option> */}
													</select>
													{errors.Department && (<span className="contactError"> {errors?.Department?.message}</span>)}
												</div>
												<div className="input-form select-form position-relative mb-4 col-md-6 col-12">
													<select className="contact-select" name="industry" id="cars"
														// value={id} 
														onChange={OnChange}
													>
														{/* <option value="" selected>Select your option</option>
                                                        {vacanciesData?.vancaciesST?.map(vacancy => {
                                                            return <option value={vacancy?.id}>{vacancy?.jobTitle}</option>
                                                        })} */}
														<option value="" hidden selected> Industry*</option>
														{Interested?.homeDataST?.map(interestedItem => {
															if (interestedItem?.type === 'WhatWeDo') {

																return (
																	<option value={interestedItem?.id}>{interestedItem?.achievementTitle}</option>
																)
															}
														})}
														{/* <option value="mercedes">Logistics</option>
                                                        <option value="audi">ERP</option>
                                                        <option value="audi">CRM</option>
                                                        <option value="audi">Healthcare</option>
                                                        <option value="audi">Other</option> */}
													</select>
													{errors.industry && (<span className="contactError">{errors?.industry?.message}</span>)}
												</div>
												<div className="input-form select-form position-relative mb-4 col-md-6 col-12">
													<select className="contact-select" name="budget" id="cars"
														// value={id} 
														onChange={OnChange}
													>
														{/* <option value="" selected>Select your option</option>
                                                        {vacanciesData?.vancaciesST?.map(vacancy => {
                                                            return <option value={vacancy?.id}>{vacancy?.jobTitle}</option>
                                                        })} */}
														<option value="" hidden selected> Your Budget*</option>
														<option value="Less than $10,000,"> Less than $10,000,</option>
														<option value="$10,000 - $25,000">$10,000 - $25,000</option>
														<option value="$25,000 - $50,000">$25,000 - $50,000</option>
														<option value="$50,000 and above">$50,000 and above</option>
													</select>
													{errors.budget && (<span className="contactError">{errors?.budget?.message}	</span>)}
												</div>
												<div className="input-form position-relative mb-4 col-12">
													<textarea className="contact-input w-100" name="message" placeholder="message*" id="" style={{ height: 125 }} rows="10" onChange={OnChange} />
													{errors.message && (<span className="contactError">{errors?.message?.message} </span>)}
												</div>
												<div className="form_submit col-12">
													<input
														type="submit"
														title="submit"
														className="form-submit"
													/>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>}
					</ul>
				</div>
			</nav>
		</header>
	)
}