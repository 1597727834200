import React, { useState, useEffect } from 'react';
import { useLazyQuery, useQuery } from "@apollo/client"
import Images from "../Config/Images"
import { eventCount, getAllEvents } from "../queries/events"
import ReactPaginate from "react-paginate";

export const Event = () => {
    const [PageCount, _PageCount] = useState(1)
    const [Offset, _Offset] = useState(1)
    const [getEvents, { data: eventsData }] = useLazyQuery(getAllEvents)
    const { data: count } = useQuery(eventCount)
    
    useEffect(() => {
        _PageCount(Math.ceil(count?.eventsSConnection?.aggregate?.count / 5))
    }, [count]);

    useEffect( () => {
        const firstIndex = ((5) * (Offset - 1));
        const lastIndex = firstIndex + 5
        const events = async ()=>{
            await getEvents({ variables: { "first": lastIndex, "skip": firstIndex, "where": null, "orderBy": null } })
        }
        events();
    }, [Offset])

    const handlePageClick = async (e) => {
        const selected = e.selected;
        _Offset(selected + 1);
    }
    return (
        <>
            <section className="services-banner">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 pr-lg-5">
                            <div className="banner-content">
                                <h1 className="banner-title">Events and Conferences</h1>
                                <p className="banner-contain">Lorem Ipsum is simply dummy text of the printing and typesetting
                                    industry. Lorem Ipsum has been the industry’s standard dummy text ever</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <img className="d-block mx-auto" src={Images.eventbanner} alt="igm" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="event-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-12 mx-auto mb-5">
                            <div className="section-title">
                                <h2 className="common-title">Events</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {eventsData?.eventsS?.map((item) => {
                            var date = new Date(item?.eventDateTime)
                            const convertedDate = date.toDateString()
                            date = convertedDate.substring(4)
                            return (
                                <div className="col-lg-4 col-md-6 col-sm-12 events">
                                    <div className="event">
                                        <a href="#">
                                            <div className="event-img"><img src={item?.eventBanner?.url} width='365px' height='250px' /></div>
                                        </a>
                                        <div className="event-details">
                                            <h3 className="event-text">{item?.eventTitle}</h3>
                                            <p className="text-muted">{item?.eventDescription}</p>
                                        </div>
                                        <p className="event-date">{date}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="pagination justify-content-end">
                        <ReactPaginate
                            previousLabel={""}
                            nextLabel={<img src={Images.rightarrow} />}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={PageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination justify-content-end "}
                            subContainerClassName={"page-item"}
                            activeClassName={"active"}
                        // forcePage={Offset - 1}
                        />
                    </div>
                </div>
            </section>
        </>
    )
}