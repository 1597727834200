import { gql } from "@apollo/client";

export const getCulture = gql`
 query($first: Int, $skip: Int, $stage: Stage!, $where: CultureImageWhereInput, $orderBy: CultureImageOrderByInput ) {
  cultureImages(first: $first skip: $skip stage: $stage where: $where orderBy: $orderBy ) {
      id
      cultureImage {
        url
      }
      cultureCategoriesST {
        categoryName
      }
    }
  }
`;

export const getCultureCategories = gql`
query{
  cultureCategoriesST {
    id
    categoryName
  }
}
`