import Images from "../Config/Images"
import { Ouropinion } from "../Components/HomeModule/Opinion";
import { useQuery } from "@apollo/client";
import { serviceQuery } from "../queries/services";

const services = [
    {
        src: Images.s1,
        title: 'Web Design & Development',
        contain: 'Mobile Friendly Responsive website Designing and Development using next generation cutting edge technology.'
    },
    {
        src: Images.smartphone,
        title: 'Mobile App Development',
        contain: 'We develop a Hybrid Mobile Applications using React-Native(FACEBOOK) framework, which let you overall budget down and save the time'
    },
    {
        src: Images.Ecommercesolution,
        title: 'E-commerce Development',
        contain: 'We can move your offline store to online and help to increase the revenue bu proving e- commerce solution.E - Commerce web development has indeed become a necessity instead of a means to drive greater traffic.'
    },
    {
        src: Images.s4,
        title: 'Build an MVP',
        contain: 'A minimum viable product is a product with just enough features to satisfy early customers, get the reviews.We build an MVP which cover minimum requirements to test end to end business idea.'
    },
    {
        src: Images.Outline,
        title: 'Growth Marketing/SEO',
        contain: 'Search engines use SEO texts to analyze websites…'
    },
    {
        src: Images.poc,
        title: 'POC',
        contain: 'A proof of concept (POC) is a demonstration, the purpose of which is to verify that certain concepts or theories have the potential for real - world application.POC is therefore a prototype that is designed to determine feasibility, but does not represent deliverables.'
    },
    {
        src: Images.productlife,
        title: 'Re-Invent the Product',
        contain: 'We re-invent your product, means we upgrade the technologies used and study the product. Based on that we decide the latest trending techology and we will move whole your product to next platform which will help you to get user friendly expereince, performance and security.'
    },
    {
        src: Images.sellproduct,
        title: 'Product Scaling',
        contain: 'We scale the product by looking into cutting edge technologies product built with, based on the outcome we will scale your product to next level which will help business to scale.'
    },
    {
        src: Images.s1,
        title: 'Desktop App Development',
        contain: 'Get the Desktop User Expereince for your Product. We build all kind of Desktop application for any kind of solutions.'
    },
    {
        src: Images.search,
        title: 'Optimization',
        contain: 'We do optimization of the product, we scan your product end to end and find the performance, optimization and secuirty related issues and based on outcome we provide the best solution.'
    },
    {
        src: Images.piechart,
        title: 'Data Analysis',
        contain: 'You have the Data, want Insights and better representation? We are here, will develop an Interactive Analytical Dashboards for you so you can read the data easily, better way and even you have more insights too.'
    },
    {
        src: Images.employee,
        title: 'Hire Full-Time',
        contain: 'Hire Us for long/short term, we provide the best developers in leading companies.'
    }
]
const wework = [
    {
        src: Images.rating,
        title: 'We Assess',
        contain: "We do end-to-end analysis of a project's scope, scale, deliverables, objectives, and impacts. It also involves assessing the project's stakeholders, risks, timeline, and impacted groups and processes."
    },
    {
        src: Images.paintbrush,
        title: 'We Design',
        contain: 'We design next-generation UI/UX as per the assessment and by considering targeted audiance'
    },
    {
        src: Images.rocket,
        title: 'We Deploy',
        contain: 'We manage the end-to-end deployment of the project and monitor continuously for zero downtime'
    },
    {
        src: Images.timemanagement,
        title: 'We Manage',
        contain: 'After deploying the project successfully, we manage it completely to provide end-to-end support as per needed'
    },
]
const action = [
    {
        class: 'blue-box',
        bgblue: 'bg-blue',
        src: Images.settings,
        title: 'Easy Setup',
        data: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s,'
    },
    {
        class: 'green-box',
        bgblue: 'bg-green',
        src: Images.usergroup,
        title: 'Experienced people',
        data: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s,'
    },
    {
        class: 'orange-box',
        bgblue: 'bg-orange',
        src: Images.tyreexpert,
        title: 'Dedicated experts',
        data: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s,'
    },
    {
        class: 'yellow-box',
        bgblue: 'bg-yellow',
        src: Images.headphones,
        title: 'Premium support',
        data: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s,'
    },
    {
        class: 'pink-box',
        bgblue: 'bg-pink',
        src: Images.lowprices,
        title: 'Low pricing',
        data: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s,'
    },
    {
        class: 'sky-box',
        bgblue: 'bg-sky',
        src: Images.tech,
        title: 'Proven tools',
        data: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s,'
    },
]

export const ServicesPage = () => {
    const { data } = useQuery(serviceQuery);
    return (
        <>
            {/* service banner */}
            < section className="services-banner" >
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 pr-lg-5">
                            <div className="banner-content">
                                <h1 className="banner-title">Maximize Business Benefits out of IT Resources.</h1>
                                <p className="banner-contain">Scaleteam provides web development,Mobile Apps development services
                                    designed to help you grow your business, increase your ROI, and one-up the competition.</p>
                                <a href="#" className="common-btn d-inline-block">KNOW MORE</a>
                            </div>
                        </div>
                        <div className="col-lg-6 pt-lg-0 pt-4">
                            <img src={Images.servicebanner} className="d-block mx-auto" alt="igm" />
                        </div>
                    </div>
                </div>
            </section >
            {/* our service */}
            < section className="our-service" >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-12 mx-auto">
                            <div className="section-title">
                                <h2 className="common-title">Our Services</h2>
                            </div>
                        </div>
                    </div>
                    <div className="timeline-data">
                        {data?.servicesST?.map((item, index) => {
                            return (
                                <div className="sevice-box">
                                    <div className={`service-contain ${index % 2 == 0 ? "left" : 'right'}`}>
                                        <img className={`service-img ${index % 2 == 0 ? "" : 'float-right'}`} src={item?.icon?.url} alt="" />
                                        <div className={`service-details ${index % 2 == 0 ? "" : 'text-right ml-0'}`}>
                                            <h3 className="service-box-title">{item?.title}</h3>
                                            <p className="service-box-contain">{item?.serviceDescription}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section >
            {/* process of Excellence */}
            < section className="process-excellence" >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-12 mx-auto">
                            <div className="section-title">
                                <h2 className="common-title">How do we work? The Process of Excellence!</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center work-row">
                        <div className="col-lg-5  col-sm-12">
                            <img className="work-img-fluid d-block mx-auto" src={Images.getstarted} />
                        </div>
                        <div className="col-lg-7  col-sm-12 work-bg">
                            <div className="row">
                                {wework.map((item) => {
                                    return (
                                        <div className="col-sm-6 work-flow">
                                            <img src={item.src} />
                                            <h3 className="work-flow-title">{item.title}</h3>
                                            <p className="work-flow-contain">{item.contain}</p>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Next Action */}
            <section className="next-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-12 mx-auto">
                            <div className="section-title">
                                <h2 className="common-title">Why are we your ‘Next Action’ for profit?</h2>
                                <p className="thought-title-contain">We, at ScaleTeam, believe in working in partnerships with our clients. Each of our clients is equally important to us and we collaborate to understand their unique needs and working methods. We consistently interact and work together to create intelligent solutions that meet our client’s evolving business demands. And thus, provide quick turnarounds by following this collaborative style of working. We have been continuously bridging the gap between business and technology.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row action-row">
                        {action.map((item) => {
                            return (
                                <div className="col-lg-4 col-md-6 col-sm-12 action-box mb-5">
                                    <div className={`${item.class} mr-90`}>
                                        <div className={`box ${item.bgblue}`}>
                                            <img src={item.src} />
                                        </div>
                                        <h3 className="action-box-title">{item.title}</h3>
                                        {/* <p className="action-box-contain">{item.data}</p> */}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
            {/* our thought */}
            {/* <Ouropinion /> */}
        </>
    )
}