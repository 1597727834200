import './App.css';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Wrapper } from './Layouts/Wrapper';
import { HomePage } from './Pages/Home.page';
import { AboutPage } from './Pages/About.page';
import { ServicesPage } from './Pages/Services.page';
import { OurworkPage } from './Pages/Ourwork.page';
import { CareerPage } from './Pages/Career.page';
import { WorkDetail } from './Pages/WorkDetail.page';
import { Careerform } from './Pages/Careerform.page';
import { Blog } from './Pages/Blog.page';
import { Blogdescrption } from './Pages/BlogDescrption.page';
import { Champion } from './Pages/Champion.page';
import { Event } from './Pages/Event.page';
import { Culture } from './Pages/Culture.page';
import { Contact } from './Pages/Contact.page';
function App() {
  return (
    <div className="App">
      <Switch>
        <PublicRouter key={'HOMEIndex'} exact path='/' component={HomePage} />
        <PublicRouter key={'HOME'} exact path='/home' component={HomePage} />
        <PublicRouter key={'ABOUT'} exact path='/about' component={AboutPage} />
        <PublicRouter key={'SERVICES'} exact path='/services' component={ServicesPage} />
        <PublicRouter key={'OURWORK'} exact path='/our-work' component={OurworkPage} />
        <PublicRouter key={'CAREER'} exact path='/career' component={CareerPage} />
        <PublicRouter key={'WORKDETAIL'} exact path='/work-detail/:id' component={WorkDetail} />
        <PublicRouter key={'WORKDETAIL'} exact path='/blog' component={Blog} />
        <PublicRouter key={'CAREERFORM'} exact path='/career-form' component={Careerform} />
        <PublicRouter key={'CAREERFORM'} exact path='/career-form/:id' component={Careerform} />
        <PublicRouter key={'BLOGDESCRPTION'} exact path='/blog-descrption/:blog' component={Blogdescrption} />
        <PublicRouter key={'CHAMPION'} exact path='/champion' component={Champion} />
        <PublicRouter key={'EVENT'} exact path='/event' component={Event} />
        <PublicRouter key={'CULTURE'} exact path='/culture' component={Culture} />
        <PublicRouter key={'CULTURE'} exact path='/contact' component={Contact} />
        <Route path='*' render={() => <Redirect to='/' />} />
      </Switch>
    </div>
  );
}

const PublicRouter = ({ component: Component, ...rest }) => {
  return (
    <Wrapper>
      <Route  {...rest} render={(props) => { return (<Component {...props} {...rest} />) }} />
    </Wrapper>
  )
}
export default App;
