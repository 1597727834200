import { useEffect, useRef } from "react";
import { HomeBanner } from "../Components/HomeModule/HomeBanner";
import Images from "../Config/Images";
import Slider from "react-slick";
import { Ouropinion } from "../Components/HomeModule/Opinion";
import { Getquote } from "../Components/HomeModule/Getquote";
import { useQuery } from "@apollo/client";
import { getAllClient } from "../queries/testimonial";
import { allTrusted } from "../queries/whowetrust";
import { getAchievements } from "../queries/achievements";
import { getAllExpertise } from "../queries/expertise";

const Clientlist = [
  {
    src: Images.virus,
    title: "28+",
    subtitle: "WORK ON COUNTRIES",
    class: "country-box",
  },
  {
    src: Images.customer,
    title: "200+",
    subtitle: "Our Clients",
    class: "country-red-box",
  },
  {
    src: Images.startup,
    title: "300+",
    subtitle: "DELIVER PROJECTS",
    class: "country-box",
  },
];

const trustedslider = {
  dots: false,
  autoplay: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};
export const HomePage = () => {
  const sliderRef = useRef();
  const VerticalSliderRef = useRef();
  const { data: clientData } = useQuery(getAllClient);
  const { data: whoWeTrustData } = useQuery(allTrusted);
  const { data: achievementsData } = useQuery(getAchievements);
  const { data: expertiseData } = useQuery(getAllExpertise);

  useEffect(() => {
  }, []);
  const silder1Container = {
    dots: false,
    arrows: false,
    autoplay: false,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => VerticalSliderRef.current.slickGoTo(next),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const verticalslider = {
    dots: false,
    autoplay: false,
    speed: 800,
    arrows: true,
    centerMode: true,
    centerPadding: "30px",
    // asNavFor: ".customer-slider",
    vertical: true,
    verticalSwiping: true,
    //   slidesToShow: 5,
    slidesToScroll: 1,
    beforeChange: (current, next) => sliderRef.current.slickGoTo(next),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          vertical: false,
          centerPadding: "75px",
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          vertical: false,
          centerPadding: "60px",
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          vertical: false,
          centerPadding: "10px",
        },
      },
    ],
  };
  return (
    <div className="scaleteam-home">
      <HomeBanner />
      <section className="what-do-section">
        <div className="container">
          <div className="what-do-title row m-0">
            <div className="dots col-lg-6 mx-auto">
              <div className="linetop"></div>
              <h4 className="we-do-title">What we Do</h4>
            </div>
          </div>
          <div className="row system-techno">
            {achievementsData?.homeDataST?.map((item) => {
              if (item.type === "WhatWeDo") {
                return (
                  <div className="col-lg-3 col-sm-6">
                    <div className="dev-content-data">
                      <a href="" className="dev-icon">
                        {item?.iconPath && (
                          <img src={item?.iconPath?.url} width="37" />
                        )}
                      </a>
                      <h4 className="dev-title">{item?.achievementTitle}</h4>
                      <p className="dev-contain">{item?.achievementDesc}</p>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </section>
      <section className="archive-ment">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <h2 className="common-title">Achievement</h2>
                <p className="common-title-contain">
                  Every Business is unique. We pick the right tools for your
                  Business
                </p>
              </div>
            </div>
          </div>
          <div className="archive-ment-row row">
            {achievementsData?.homeDataST?.map((item) => {
              if (item.type === "achievement") {
                return (
                  <div key={item?.id} className="col-md-6 mb-5 pr-lg-5">
                    <div className="we-archivement d-flex flex-sm-row flex-column align-items-center">
                      {item?.iconPath && (
                        <img src={item?.iconPath?.url} width="125px" />
                      )}
                      <div className="archive-right-data pr-lg-5">
                        <h4 className="archive-title text-sm-left text-center">
                          {item?.achievementTitle}
                        </h4>
                        <p className="archive-contain text-sm-left text-center">
                          {item?.achievementDesc}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </section>
      <section className="expertise-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <h2 className="common-title">Expertise</h2>
              </div>
            </div>
          </div>
          <div className="expertise-row row">
            <div className="col-lg-3">
              <h3 className="expert-techno-title text-lg-left text-center">
                Technology is an Art, We are Artist.
              </h3>
              <p className="expert-techno-contain text-lg-left text-center">
                Every Business is unique. We pick the right tools for your
                business
              </p>
            </div>
            <div className="col-lg-9 mt-lg-0 mt-4">
              <div className="row">
                {expertiseData?.expertiseST?.map((item) => {
                  return (
                    <div key={item?.id} className="col-lg-3 col-sm-4">
                      <div
                        className="all-technology"
                        style={{ borderColor: item?.color?.hex }}
                      >
                        {item?.logoPath && (
                          <img
                            className="techno-img"
                            src={item?.logoPath?.url}
                            alt=""
                          />
                        )}
                        <p
                          className="techno-common-name "
                          style={{ color: item?.color?.hex }}
                        >
                          {item?.title}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="happy-client">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <h2 className="common-title">
                  Happy clients and Success Stories
                </h2>
              </div>
            </div>
          </div>
          <div className="row slider-row align-items-center m-0">
            <div className="col-lg-8">
              <div className="dotted-top">
                <img src={Images.dotted} alt="" />
              </div>
              <div className="row slider-box m-0">
                <div className="col-lg-9 d-flex align-items-center pl-lg-4">
                  <Slider
                    ref={sliderRef}
                    {...silder1Container}
                    className="w-100 pt-md-0 pt-4"
                  >
                    {clientData?.testimonialsST?.map((clientData) => {
                      return (
                        <div key={clientData?.id} className="client-detail position-relative d-sm-flex align-items-start flex-column">
                          <img
                            className="quote-icon"
                            src={Images.quote}
                            alt=""
                          />
                          <div className="d-flex flex-lg-row flex-column align-items-center">
                            <div className="client-img">
                              <img src={clientData?.logoPath?.url} alt="" />
                            </div>
                            <div className="client-review">
                              <h4 className="client-review-title text-lg-left text-center">
                                {clientData?.clientName}
                              </h4>
                              <p className="client-review-subtitle text-lg-left text-center">
                                {clientData?.companyName}
                              </p>
                              <p className="client-review-contain text-lg-left text-center">
                                {clientData?.clientDescription}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
                <div className="col-lg-3 position-relative pt-2">
                  <Slider
                    ref={VerticalSliderRef}
                    slidesToShow={clientData?.testimonialsST?.length - 1 || 0}
                    {...verticalslider}
                    className="client-img-data pt-md-0 pt-4"
                  >
                    {clientData?.testimonialsST?.map((clientData) => {
                      return (
                        <div className="client-img-vertical">
                          <img src={clientData?.logoPath?.url} alt="" />
                        </div>
                      );
                    })}
                  </Slider>
                </div>
              </div>
              <div className="dotted-bottom">
                <img src={Images.dotted} alt="" />
              </div>
            </div>
            <div className="col-lg-4 col-md-9 col-sm-10 mt-lg-0 mt-5 mx-auto">
              <div className="country-client">
                {Clientlist.map((item, index) => {
                  return (
                    <div className={`${item.class}`}>
                      <img src={item.src} alt="" />
                      <h5
                        className={`${index == 0
                            ? "country-title"
                            : `${index == 1
                              ? "country-red-title"
                              : "country-title"
                            }`
                          }`}
                      >
                        {item.title}
                      </h5>
                      <p
                        className={`${index == 0
                            ? "expand-work"
                            : `${index == 1 ? "expand-red-work" : "expand-work"
                            }`
                          }`}
                      >
                        {item.subtitle}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <Ouropinion /> */}
      <section>
        <div className="container">
          <div className="row">
            <div className="col-9 mx-auto">
              <div className="section-title">
                <h2 className="common-title">Who Trusted Us?</h2>
                <p className="thought-title-contain pt-1 text-center">
                  Customer satisfaction is our motto
                </p>
              </div>
            </div>
          </div>
          <div className="trusted-company">
            <div className="trusted-slider w-100">
              <Slider {...trustedslider} className="w-100">
                {whoWeTrustData?.whoWeTrusts?.map((whoWeTrusts) => {
                  return (
                    <div key={whoWeTrusts?.id} style="width: 20%;">
                      <img
                        src={whoWeTrusts?.logoPath?.url}
                        alt=""
                        width="100%"
                      />
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>
      </section>
      <Getquote />
    </div>
  );
};
