import { gql } from "@apollo/client";

export const allTrusted = gql`
  query {
    whoWeTrusts {
      id
      logoPath {
        url
      }
    }
  }
`;
