import { gql } from "@apollo/client";

export const getAllTeams = gql`
  query {
    teams {
      id
      developerName
      developerDesignation
      developerDesc
      developerImage {
        url
      }
    }
  }
`;
