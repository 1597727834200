import Images from "../Config/Images"
import { Ourvision } from "../Components/HomeModule/Ourvision";
import { Workflow } from "../Components/HomeModule/Workflow";
import { useQuery } from "@apollo/client";
import { allDepartments } from "../queries/career";
import { useHistory } from "react-router";
const benefits = [
    {
        src: Images.perksOne,
        title: 'Inclusion and diversity'
    },
    {
        src: Images.perksFour,
        title: 'Growth and opportunity'
    },
    {
        src: Images.perksThree,
        title: 'Constant learning'
    },
    {
        src: Images.perksTwo,
        title: 'Build network'
    },
]
export const CareerPage = () => {
    const { data: Departments } = useQuery(allDepartments)
    let history = useHistory()
    return (
        <>
            <section className="services-banner">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 pr-lg-5">
                            <div className="banner-content">
                                <h1 className="banner-title">Change your life with Scaleteam.</h1>
                                <p className="banner-contain">We believe in the freedom to think and work to our best potential.
                                    Organized living is what we nurture both in our work process and life.</p>
                                <a href="/career-form" className="common-btn d-inline-block">Apply Now</a>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-lg-0 mt-4">
                            <img className="mx-auto d-block" src={Images.careerbanner} alt="igm" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="thigns-work">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7 col-sm-12">
                            <h5 className="thigns-work-title">SOME THINGS ABOUT YOU</h5>
                            <div className="thing">
                                <h3>01</h3>
                                <h4>Nobody outworks you.</h4>
                            </div>
                            <div className="thing2">
                                <h3>02</h3>
                                <h4>You want change how the world works.</h4>
                            </div>
                            <div className="thing3">
                                <h3>03</h3>
                                <h4>You’re good at anything you put your mind to.</h4>
                            </div>
                            <div className="thing4">
                                <h3>04</h3>
                                <h4>You’re an excellent team player.</h4>
                            </div>
                        </div>
                        <div className="col-md-5 col-sm-12 work-details mt-md-0 mt-4">
                            <h4 className="who-title">WHO</h4>
                            <h2>Who should work at Scaleteam?</h2>
                            <p>At ScaleTeam, we are looking for talented individuals who aim to bring meaningful change to our clients' businesses by enabling them to create and develop innovative technology solutions. We believe in developing talent in all stages of their career and hence continuously invest in upskilling and nurturing the talents of our workforce.

We empower our team with process and technology and encourage them to think creatively and drive bold changes to clients' agencies and businesses. ScaleTeam is a hub for believers, thinkers, creators, and doers. You are welcome to join an enthusiastic team of zealous individuals in the world of technology and innovation. Join our quest in keeping up with the latest technology, innovation, and sustained professional and market growth.

If you are a passionate, curious, creative, and solution-driven team player driven by new challenges, join our team today.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="vacancies-section pt-0">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-12 mx-auto">
                            <div className="section-title text-center">
                                <h4 className="work-details-title">VACANCIES</h4>
                                <h2 className="common-title">Be Part of Something Great</h2>
                                <p className="vacancies-title-contain">Love your work. Join your dream team</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mt-5">
                            <div className="row">
                                {Departments?.departmentST?.map(department => {
                                    return (
                                        <div className="col-lg-6  col-xs-12 design-one" style={{ marginBottom: '40px' }}>
                                            <h1 className="common-career" style={{ background: department?.colorStyle?.hex }}>{department?.department}</h1>
                                            <div className="design">
                                                <div className="design-timeline">
                                                    {department?.vancaciesST?.map(vacancy => {
                                                        return (
                                                            <div className="design-timeline-cards">
                                                                <div className="design-timeline-card card mt-5" style={{ borderColor: department?.colorStyle?.hex }}>
                                                                    <h3>{vacancy?.jobTitle}</h3>
                                                                    <a style={{ background: department?.colorStyle?.hex }} onClick={() => history.push(`/career-form/${vacancy?.id}`)} className="button">Apply Now</a>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}

                                                    {/* <div className="design-timeline-cards">
                                                        <div className="design-timeline-card card">
                                                            <h3>Lorem Ipsum</h3>
                                                            <a href="#" className="button">Apply Now</a>
                                                        </div>
                                                    </div>

                                                    <div className="design-timeline-cards">
                                                        <div className="design-timeline-card card">
                                                            <h3>Lorem Ipsum</h3>
                                                            <a href="#" className="button">Apply Now</a>
                                                        </div>
                                                    </div> */}

                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                                {/* <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-5 engineering">
                                    <h1 className="common-career">Engineering</h1>
                                    <div className="design">
                                        <div className="design-timeline">
                                            <div className="design-timeline-cards">
                                                <div className="design-timeline-card card card2 mt-5">
                                                    <h3>Lorem Ipsum</h3>
                                                    <a href="#" className="button">Apply Now</a>
                                                </div>
                                            </div>

                                            <div className="design-timeline-cards">
                                                <div className="design-timeline-card card card2">
                                                    <h3>Lorem Ipsum</h3>
                                                    <a href="#" className="button">Apply Now</a>
                                                </div>
                                            </div>

                                            <div className="design-timeline-cards">
                                                <div className="design-timeline-card card card2">
                                                    <h3>Lorem Ipsum</h3>
                                                    <a href="#" className="button">Apply Now</a>
                                                </div>
                                            </div>

                                            <div className="design-timeline-cards">
                                                <div className="design-timeline-card card card2">
                                                    <h3>Lorem Ipsum</h3>
                                                    <a href="#" className="button">Apply Now</a>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        {/* <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 mt-5 ">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 development">
                                    <h1 className="common-career">Development</h1>
                                    <div className="design">
                                        <div className="design-timeline">
                                            <div className="design-timeline-cards">
                                                <div className="design-timeline-card card card3 mt-5">
                                                    <h3>Lorem Ipsum</h3>
                                                    <a href="#" className="button">Apply Now</a>
                                                </div>
                                            </div>
                                            <div className="design-timeline-cards">
                                                <div className="design-timeline-card card card3">
                                                    <h3>Lorem Ipsum</h3>
                                                    <a href="#" className="button">Apply Now</a>
                                                </div>
                                            </div>
                                            <div className="design-timeline-cards">
                                                <div className="design-timeline-card card card3">
                                                    <h3>Lorem Ipsum</h3>
                                                    <a href="#" className="button">Apply Now</a>
                                                </div>
                                            </div>
                                            <div className="design-timeline-cards">
                                                <div className="design-timeline-card card card3">
                                                    <h3>Lorem Ipsum</h3>
                                                    <a href="#" className="button">Apply Now</a>
                                                </div>
                                            </div>
                                            <div className="design-timeline-cards">
                                                <div className="design-timeline-card card card3">
                                                    <h3>Lorem Ipsum</h3>
                                                    <a href="#" className="button">Apply Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-5 sales">
                                    <h1 className="common-career">Sales</h1>
                                    <div className="design">
                                        <div className="design-timeline">
                                            <div className="design-timeline-cards">
                                                <div className="design-timeline-card card card4 mt-5">
                                                    <h3>Lorem Ipsum</h3>
                                                    <a href="#" className="button">Apply Now</a>
                                                </div>
                                            </div>

                                            <div className="design-timeline-cards">
                                                <div className="design-timeline-card card card4">
                                                    <h3>Lorem Ipsum</h3>
                                                    <a href="#" className="button">Apply Now</a>
                                                </div>
                                            </div>

                                            <div className="design-timeline-cards">
                                                <div className="design-timeline-card card card4">
                                                    <h3>Lorem Ipsum</h3>
                                                    <a href="#" className="button">Apply Now</a>
                                                </div>
                                            </div>

                                            <div className="design-timeline-cards">
                                                <div className="design-timeline-card card card4">
                                                    <h3>Lorem Ipsum</h3>
                                                    <a href="#" className="button">Apply Now</a>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
            <section className="benefits-section">
                <div className="container">
                    <div className="row Benefits">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <h4 className="text-center">PERKS AND BENEFITS</h4>
                            <h2 className="text-center">Scaleteam Perks and Benefits.</h2>
                            <div className="row">
                                {benefits.map((item) => {
                                    return (
                                        <div className="col-lg-3 col-md-6 col-sm-12 mt-5">
                                            <div className="Benefit text-center">
                                                <img src={item.src} />
                                            </div>
                                            <h3>{item.title}</h3>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Workflow />
            <Ourvision />
        </>
    )
}