import { gql } from "@apollo/client";

export const serviceQuery = gql`
{
  servicesST{
    id
    title
    serviceDescription
    icon{
      url
    }
  }
  }`