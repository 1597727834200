import { gql } from "@apollo/client";

export const getAllClient = gql`
  query {
    testimonialsST {
      id
      clientName
      companyName
      clientDescription
      logoPath {
        url
      }
    }
  }
`;
