import { useLazyQuery, useQuery } from "@apollo/client"
import { useEffect, useState } from "react"
import Images from "../Config/Images"
import { getCultureCategories, getCulture } from "../queries/culture"

export const Culture = () => {
    const [getCultures, { data: culturedata }] = useLazyQuery(getCulture)
    const { data: allCultureCategories } = useQuery(getCultureCategories)
    const [EventTab, _EventTab] = useState(1)
    const [where, _where] = useState({})
    useEffect(() => {
        if (EventTab != 1)
            _where({ cultureCategoriesST_every: { id: EventTab } })
        else
            _where({})
    }, [EventTab])

    useEffect(() => {
         getCultures({ variables: { "stage": "DRAFT", "where": where } })
    }, [where])

    return (
        <>
            <section className="services-banner">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 pr-lg-5">
                            <div className="banner-content">
                                <h1 className="banner-title">Our Culture</h1>
                                <p className="banner-contain">Lorem Ipsum is simply dummy text of the printing and typesetting
                                    industry. Lorem Ipsum has been the industry’s standard dummy text ever</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <img className="mx-auto d-block" src={Images.culturebanner} alt="igm" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="imgaing-industries">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-12 mx-auto">
                            <div className="section-title">
                                <h2 className="common-title">Our Culture</h2>
                            </div>
                        </div>
                    </div>
                    <div className="our-work-portfolio">
                        <ul className="nav nav-pills our-wrok-list justify-content-center" id="pills-tab" role="tablist">
                            <li className="nav-item" onClick={(e) => { _EventTab(1); e.preventDefault(); }}>
                                <a className={`nav-link ${EventTab == 1 && 'active'}`}>All</a>
                            </li>
                            {allCultureCategories?.cultureCategoriesST?.map(category => {
                                return (
                                    <li className="nav-item" onClick={(e) => { _EventTab(category?.id); e.preventDefault(); }}>
                                        <a className={`nav-link ${EventTab == category?.id && 'active'}`} >{category?.categoryName}</a>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    <div className="potfolio-data">
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel"
                                aria-labelledby="pills-home-tab">
                                <div className="row">

                                    <div className="col-12">
                                        <div className="row">
                                            {culturedata?.cultureImages?.map((item) => {
                                                return (
                                                    <>
                                                        <div className="col-lg-6">
                                                            <div className="row">
                                                                <div className="col-lg-8">
                                                                    <div className="row">
                                                                        <div className="col-12">
                                                                            <div className="pionner-img">
                                                                                <img src={item?.cultureImage?.url} width="100%" height="240px"
                                                                                    alt="" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <a href="#" className="common-btn join-our-team text-center d-inline-block"
                                        style={{ margin: '51px auto 0' }}>Join Our Team</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}