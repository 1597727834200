import Images from "../Config/Images"



export const Contact = () => {
    return (
        <>
            <section className="champion-banner">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 pr-lg-5">
                            <div className="banner-content">
                                <h1 className="banner-title">Got a question?</h1>
                                <p className="banner-contain">Let us take your ideas and give them flight. Our team is ready with
                                their skills and dedication to bring your ideas to light. Drop us a note here and we will
                            get back to you within 24 hours.</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <img className="d-block mx-auto" src={Images.contactbanner} alt="igm" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="contact-form-data">
                <div className="contact-container container">
                    <div className="row m-0">
                        <div className="col-12 mx-auto">
                            <div className="section-title">
                                <h2 className="common-title">Contact</h2>
                                <p className="common-title-contain pt-1 text-center">Whether you have questions or you would just
                            like to say hello, contact us.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-between contact-form-row m-0">
                        <div className="col-lg-5">
                            <div className="company-loaction">
                                <div className="loaction-detail">
                                    <img src={Images.loactions} alt="" />
                                    <p className="loaction-address">301-302, Amora Arcade, Patidar Chowk, near Mauni International
                                    School, Uttran, Surat,
                                Gujarat 394105</p>
                                </div>
                                <div className="email-section">
                                    <h4 className="email-title">Email</h4>
                                    <p className="email-address">business@scale-team.com</p>
                                    <p className="email-address">hr@scale-team.com</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-lg-0 mt-4">
                            <div className="form contact-from">
                                <div className="input-form">
                                    <input className="contact-input" type="text" placeholder="First Name*" />
                                </div>
                                <div className="input-form">
                                    <input className="contact-input" type="text" placeholder="Last Name*" />
                                </div>
                                <div className="input-form">
                                    <input className="contact-input" type="email" placeholder="Email*" />
                                </div>
                                <div className="input-form">
                                    <input className="contact-input" type="number" placeholder="Phone NUmber*" />
                                </div>
                                <div className="input-form">
                                    <input className="contact-input" type="text" placeholder="Subject*" />
                                </div>
                                <div className="input-form">
                                    <textarea className="contact-text-area" name="" id="" placeholder="Message*" cols="30"
                                        rows="10"></textarea>
                                </div>
                                <button className="form-submit">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}