import { gql } from "@apollo/client";

export const allBlogsCategories = gql`
  query {
    blogsCategoriesST {
      categoryName
      id
    }
  }
`;

export const allBlogs = gql`
  query blogArticlesST(
    $first: Int
    $skip: Int
    $orderBy: BlogArticlesOrderByInput
    $where: BlogArticlesWhereInput
  ) {
    blogArticlesST(
      skip: $skip
      orderBy: $orderBy
      first: $first
      where: $where
    ) {
      id
      articleTitle
      articleDescription {
        text
      }
      articleSubDescription
      articleBanner {
        url
      }
    }
  }
`;

export const getBlog = gql`
  query ($where: BlogArticlesWhereUniqueInput!) {
    blogArticles(where: $where) {
      id
      articleTitle
      articleSubDescription
      articleBanner {
        url
      }
      articleDescription {
        text
      }
    }
  }
`;
