import Images from "../../Config/Images";
import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router";
import { useForm } from "react-hook-form";
import { addContact } from "../../queries/contactRequest";
import swal from "sweetalert";
import { $ } from "react-jquery-plugin";

export const HomeBanner = () => {
  let history = useHistory();
  const {
    setError,
    formState: { errors },
  } = useForm();
  const [contact, setContact] = useState({
    firstName: null,
    lastName: null,
    email: null,
    phoneNumber: null,
    subject: null,
  });
  const [model, setModel] = useState(false);
  const [createContact] = useMutation(addContact);
  const onChangeContact = (event) => {
    const contacts = Object.entries(contact);
    contacts?.map((contact) => {
      if (contact[1] === null) {
        setError(contact[0], {
          message: `${contact[0].replace(
            contact[0][0],
            contact[0][0].toUpperCase()
          )} is Required`,
        });
      }
    });

    if (event.target.value === "" || event.target.value === null) {
      setError(event.target.name, {
        message: `${event.target.name.replace(
          event.target.name[0],
          event.target.name[0].toUpperCase()
        )} is Required`,
      });
    } else if (
      event.target.name === "phoneNumber" &&
      event.target.value.match(/^\d{10}$/) === null
    )
      setError("phoneNumber", {
        message: "PhoneNumber is not Valid",
      });
    else if (
      event.target.name === "email" &&
      event.target.value.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ) === null
    )
      setError("email", {
        message: "Email is not Valid",
      });
    else {
      let value = event.target.value;
      setError(event.target.name, {
        message: null,
      });
      if (event.target.name === "phoneNumber") {
        value = parseInt(value);
      }
      setContact({
        ...contact,
        [event.target.name]: value,
      });
    }
  };
  const submitHandler = (event) => {
    event.preventDefault();
    if (
      (errors?.firstName?.message ||
        errors?.lastName?.message ||
        errors?.email?.message ||
        errors?.phoneNumber?.message ||
        errors?.subject?.message) === null
    ) {
      createContact({
        variables: {
          data: contact,
        },
      });
      $(".modal-backdrop.show").hide();
      setModel(false);
      swal("Success", "Successfully added", "success");
      history.push("/");
    } else {
      const error = Object.entries(errors);
      error?.map((error) => {
        if (error[1]?.message !== null) {
          swal("Error", error[1]?.message, "error");
          setModel(true);
        }
      });
      if (error?.length === 0) {
        swal("Error", "Please fill all Details ! ", "error");
      }
    }
  };
  return (
    <section className="home-banner">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 pr-lg-5">
            <div className="banner-content">
              <h1 className="banner-title">
                We Help Startups, Collaborate to Innovate.
              </h1>
              <p className="banner-contain">
                We Develop Next Generation Web And Mobile Apps Using NodeJS,
                Meteor, React, React Native and Apollo-GraphQL. That Move As
                Fast As Your Business.
              </p>
              <a
                href="#"
                className="common-btn d-inline-block"
                data-toggle="modal"
                data-target="#exampleModalCenter"
                onClick={() => setModel(true)}
              >
                Get Started
              </a>
              {model && (
                <div
                  className="modal fade startup_home-modal"
                  id="exampleModalCenter"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalCenterTitle"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-centered"
                    role="document"
                  >
                    <div className="modal-content home_form-modal border-0">
                      <div className="modal-body">
                        <div className="d-inline-block w-100">
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            style={{ opacity: 1 }}
                          >
                            <img
                              src={Images.cancle}
                              className="align-top"
                              className="cancle-btn"
                              alt=""
                            />
                          </button>
                        </div>
                        <div className="home-modal_form">
                          <div className="text-center">
                            <img src={Images.mainLogo} alt="" />
                            <h2 className="startup-modal-title mt-3">
                              Grow your business with us
                            </h2>
                          </div>
                          <form
                            action=""
                            className="mt-4"
                            onSubmit={submitHandler}
                          >
                            <div className="home_form mb-4">
                              <input
                                type="text"
                                placeholder="First Name*"
                                name="firstName"
                                className="contact-input"
                                onChange={onChangeContact}
                              />
                              {errors.firstName && (
                                <span className="contactError">
                                  {errors?.firstName?.message}
                                </span>
                              )}
                            </div>
                            <div className="home_form mb-4">
                              <input
                                type="text"
                                placeholder="Last Name*"
                                name="lastName"
                                className="contact-input"
                                onChange={onChangeContact}
                              />
                              {errors.lastName && (
                                <span className="contactError">
                                  {errors?.lastName?.message}
                                </span>
                              )}
                            </div>
                            <div className="home_form mb-4">
                              <input
                                type="text"
                                placeholder="Email*"
                                name="email"
                                className="contact-input"
                                onChange={onChangeContact}
                              />
                              {errors.email && (
                                <span className="contactError">
                                  {errors?.email?.message}
                                </span>
                              )}
                            </div>
                            <div className="home_form mb-4">
                              <input
                                type="number"
                                placeholder="Phone Number*"
                                name="phoneNumber"
                                className="contact-input"
                                onChange={onChangeContact}
                              />
                              {errors.phoneNumber && (
                                <span className="contactError">
                                  {errors?.phoneNumber?.message}
                                </span>
                              )}
                            </div>
                            <div className="home_form mb-4">
                              <input
                                type="text"
                                placeholder="Subject*"
                                name="subject"
                                className="contact-input"
                                onChange={onChangeContact}
                              />
                              {errors.subject && (
                                <span className="contactError">
                                  {errors?.subject?.message}
                                </span>
                              )}
                            </div>
                            <div className="form_submit mt-5">
                              <input
                                type="submit"
                                title="submit"
                                className="form-submit"
                              />
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <img
              src={Images.homeBanner}
              className="d-block mx-auto"
              alt="igm"
            />
          </div>
        </div>
      </div>
    </section>
  );
};
