import { useQuery } from "@apollo/client";
import { useState } from "react"
import Images from "../Config/Images"
import { getAllTeams } from "../queries/champions";
const championdetail = [
    {
        src: Images.vaidehi,
        name: 'Vaidehi C.',
        designation: "Software Engineer",
        fullname: 'Vaidehi Chodvadiya',
        desc: 'My Self Vaidehi Chodvadiya, I have 5 years of experience,During my work period I have been working with various technologies,including front end and backend,* I can Develop,coordinate, and facilitate projects from stage of concept to full implementation.* I have hands on experience in PHP, MySQL, Codeignator, Ajax, API Development and integrations,Laravel, JavaScript, HTML, CSS, jQuery, AngularJS, React, React-native, Firebase ,Git,* I have done projects for various domains like educational institute,e-commerce, social media, Research tool etc.'
    },
    {
        src: Images.axita,
        name: 'Akshita K.',
        designation: "Software Engineer",
        fullname: 'Akshita Koladiya',
        desc: 'My name is Akshita Koladiya , It’s been 4 years that I’m holding position as Full Stack Developer. During my work period I have been working with various technologies, including front end and backend. I can Develop, coordinate, and facilitate projects from stage of concept to full implementation. I have hands on experience in JavaScript, HTML, CSS, jQuery, ReactJs, React-native, Firebase, MongoDB, graphQL, Git, MeteorJs, NodeJs, API Development and integrations. I have done projects for various domains like e-commerce, marketing, immigration, hospital management etc. Thank You.'
    },
    {
        src: Images.amit,
        name: 'Amit T.',
        designation: "Software Engineer",
        fullname: 'Amit Tailor',
        desc: ' I am Tailor Amit full stack developer having 2.6 years of experience. I have worked various type of languages like javascript,typescript ,Php , Java , frameworks like React , React-native ,node , graphql  , Express , Database like MongoDb, Mysql and design framework live material-ui , antd ,bootstrap .'
    },
    {
        src: Images.jemin,
        name: 'Jemin S.',
        designation: "Software Engineer",
        fullname: 'Jemin Shingala',
        desc: 'I am Jemin Shingala , full stack developer having 4.5 years of experience.I have worked over various technologies like javascript,graphql,,firebase, PHP , Java , frameworks like React,React-native, Express, Node and Database like MongoDb, Mysql.'
    },
    {
        src: Images.dipak,
        name: 'Deepak T.',
        designation: "Software Engineer",
        fullname: 'Deepak Tiwari',
        desc: 'I am Deepak R Tiwari, a full stack developer having 2.5 years of experience.I have worked over various technologies like javascript, graphql, redux, firebase, Php frameworks like React, React-native, Express, Node and Databases like MongoDb, Mysql.'
    },
    {
        src: Images.hr,
        name: 'Vanita G.',
        designation: "HR",
        fullname: 'Vanita Godhwani',
        desc: 'I am Vanita Godhwani. I have completed an MBA in HR. I  have started my career as an HR Executive from ScaleTeam itself. It’s been 1 year I am holding this position, and in this journey I learn a lot. My role and responsibilities are Policy Formation, Recruitment & Selection, Induction & Exit Formalities, Training & development, Performance management, Compensation and Benefits, Employee Engagement. I’m glad and thankful to ScaleTeam for this opportunity. My future goal is to grow my company with the growth of myself.'
    },
    {
        src: Images.hiral,
        name: 'Hiral S.',
        designation: "Software Engineer",
        fullname: 'Hiral Sojitra',
        desc: 'Myself Hiral Sojitra, having 4+ years of experience in Node.js. I am working here as a Web Developer, I do have experience in Game Development. My skill includes Node.js (express.js, sails.js, hapi.js), MongoDB, Redis, Socket.io, React.js, rabbitMQ, redux.'
    },
    {
        src: Images.viral,
        name: 'Viral K.',
        designation: "Software Engineer",
        fullname: 'Viral Khunt',
        desc: 'I`m Viral Khunt, Since 4 years I am working as web developer as well as application developer.During my work period I have develop working with various technologies,including front end.I can Develop, coordinate, and facilitate projects from stage of concept to full implementation.I have hands on experience in API integrations, JavaScript, HTML, CSS, jQuery, React, React-native, Redux, Firebase ,Git, MeteorJs,I have done projects for various domains like e-commerce, educational institute, social media etc.'
    },
    {
        src: Images.ronak,
        name: 'Ronak D.',
        designation: "Web Designer & Devloper",
        fullname: 'Ronak Domadiya',
        desc: ' I am Ronak Domadiya, Web Designer and Web Devloper having 1.5 years of experience. I had worked various type of UI web layout using Html, Css, scss, Boostrap, React js, Jquery, Javascript.'
    },
    {
        src: Images.jemis,
        name: 'Jemish D.',
        designation: "Software Engineer",
        fullname: 'Jemish Dungrani',
        desc: 'I am Savan Manvar UI/UX Designer having 3 years of experience. I had work various type of UI and UX based application and web layout using user personas, User journey, Interaction design concepts, Design Rationale and Conceptual ideas. I have a passion for Web and App Design (wireframe and prototyping).'
    },
    {
        src: Images.prathna,
        name: 'Prathna D.',
        designation: "Software Engineer",
        fullname: 'Prathna Dobariya',
        desc: 'I am Prathana Dobriya, Full Stack Developer having 1.4 year of experience. I specialize in JavaScript and have professional experience working with React.js, React-native, Meteor Js and Node.js. I also have experience working with Express.js, redux,  MongoDB.'
    },
    {
        src: Images.dhruvi,
        name: 'Dhruvi S.',
        designation: "Software Engineer",
        fullname: 'Dhruvi Sutariya',
        desc: 'I am Dhruvi Sutariya, Full Stack Developer having 1.5 year experience. I exclusive in Javascript and have professional experience working with React.js and React-native. I also have experience working with Express.js, MongoDB.'
    },
    {
        src: Images.dhruti,
        name: 'Dhruti H.',
        designation: "Software Engineer",
        fullname: 'Dhruti Hirpara',
        desc: 'I am Dhruti Hirpara joined here as Recat-Native Developer. My skill includes Javascript, React.js, React-Native, MongoDB, Firebase and also have professional experience working with React-native.'
    },
    {
        src: Images.jaya,
        name: 'Jaya P.',
        designation: "Graphics Designer",
        fullname: 'Jaya Patil',
        desc: 'I am Jaya Patil Graphic Designer having 3 years of experience. I worked on various types of Video Editing, Photo Editing, Photo Frame, Logo Design, Poster Design, Banner Design, Admin panel. I have a passion for Web and App Design. I have also designed the website in Html. '
    },
    {
        src: Images.shruti,
        name: 'Shruti N.',
        designation: "Software Engineer",
        fullname: 'Shruti Nakarani',
        desc: 'I am Shruti Nakrani joined here as fresher developer.I am familiar with Javascript,React.js,MongoDB,Node.js,Php,Bootstrap,Meteor and also having 2 months experience  with Node.js'
    },
    {
        src: Images.ankit,
        name: 'Ankit M.',
        designation: "Software Engineer",
        fullname: 'Ankit Maniya',
        desc: 'I am Ankit Maniya full suck web developer having 1.5 years of mixed experience with frontend and backend. I have worked various type of languages like javascript, Php, frameworks like React, Express, Node, Codeigniter and Database like MongoDb, Mysql.'
    },
    {
        src: Images.nikul,
        name: 'Nikul B.',
        designation: "Software Engineer",
        fullname: 'Nikul Beladiya',
        desc: 'I am Nikul Beladiya, Full Stack Developer having 1 year of experience. I specialize in JavaScript and have professional experience working with Node.js and React.js. I also have experience working with Express.js, Graphql, MongoDB, MySQL, PHP, CodeIgniter, Laravel.'
    },
    {
        src: Images.raj,
        name: 'Raj S.',
        designation: "Software Engineer",
        fullname: 'Raj Sojitra',
        desc: 'I am Raj Sojitra Software Engineer having 1 years of experience.I have worked various type of languages like javascript, java, frameworks like React, React Native, Graphql, Express, Node, Android and Database like MongoDb.'
    },
    {
        src: Images.vaman,
        name: 'Vaman B.',
        designation: "Software Engineer",
        fullname: 'Vaman Bhuvani',
        desc: 'I am Vaman Bhuvani, Full Stack Developer having 1.6 year of experience. I specialize in JavaScript and have professional experience working with React.js and Node.js. I also have experience working with Express.js, GraphQL, MongoDB.'
    },
    {
        src: Images.dhruvil,
        name: 'Dhruvil K.',
        designation: "Software Engineer",
        fullname: 'Dhruvil kathiriya',
        desc: 'I am dhruvil kathiriya React Native/Flutter developer with 1 year of mobile application development experience also I have completed more than 4+ Application.I have worked on various type of application like e-commerce , social media, shopping etc.'
    },
    {
        src: Images.yash,
        name: 'Yash D.',
        designation: "Software Engineer",
        fullname: 'Yash Dhameliay',
        desc: 'I am Yash Dhameliya full stack Web Developer having 5 month of experience. I had work various type of Project based application , project Conceptual ideas. I have a passion for develop app/project'
    }

]
export const Champion = () => {
    const [modaldata, _modaldata] = useState({});
    console.log("modaldata", modaldata);
    const { data: allTeam } = useQuery(getAllTeams)
    return (
        <>
            <section className="champion-banner">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 pr-lg-5">
                            <div className="banner-content">
                                <h1 className="banner-title">Meet the team!</h1>
                                <p className="banner-contain">Sharp-witted, optimistic, and passionate, our squad incorporates all
                                    possible skills to deliver futuristic solutions to business challenges.<a href="#"
                                        className="join-us d-inline-block ml-1">Join Us</a></p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <img src={Images.championbanner} className="mx-auto d-block" alt="igm" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="champiom-img">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-title">
                                <h2 className="common-title">Our Champions</h2>
                            </div>
                        </div>
                    </div>
                    <div className="champion-row2">
                        <div className="row">
                            {allTeam?.teams?.map((item) => {
                                return (
                                    <>
                                        <div className="champion-col">
                                            <div className=" position-relative">
                                                <img src={item?.developerImage?.url} className="mx-auto d-block" alt="" />
                                                <div className="champion-bio cursor-pointer">
                                                    <div className="champion-border"></div>
                                                    <div className="champion-detail" data-toggle="modal" data-target={`#exampleModal-${item?.id}`} onClick={(e) => { _modaldata(item) }}>
                                                        <h5 className="champion-name">{item?.developerName}</h5>
                                                        <p className="champion-position">{item?.developerDesignation}</p>
                                                    </div>
                                                    <div className="champion-right-angle"></div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="modal fade employee-modal" id={`exampleModal-${item?.id}`} tabindex="-1" role="dialog"
                                            aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div className="modal-dialog champion-dialog" role="document">
                                                <div className="modal-content">
                                                    <div className="modal-body">
                                                        <button className="border-0 close-btn bg-transparent p-0" data-dismiss="modal"
                                                            aria-label="Close">
                                                            <img src={Images.cancle} className="align-top" className="cancle-btn" alt="" />
                                                        </button>
                                                        <div className="employee-data d-md-flex d-block align-items-center">
                                                            <img src={item?.developerImage?.url} className="mx-auto d-block" alt="" />
                                                            <div className="employee-full-detail mt-md-0 mt-4">
                                                                <h3 className="modal-detail-title">{item?.developerName}</h3>
                                                                <h5 className="modal-detail-subtitle">{item?.developerDesignation}</h5>
                                                                <p className="modal-detail-contain">{item?.developerDesc}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}