import Images from "../../Config/Images"

export const Footer = () => {
    return (
        <footer>
            <div className="container">
                <div className="row m-0">
                    <div className="col-md-9 mx-auto">
                        <div className="section-title">
                            <h2 className="common-title">Get in Touch</h2>
                            <p className="common-title-contain pt-1 text-center">Over the course of the last 8+ years, we have
                            created 300+ robust products</p>
                        </div>
                    </div>
                </div>
                <div className="row menu-row">
                    <div className="col-lg-7">
                        <div className="row">
                            <div className="col-md-3 col-sm-6">
                                <h4 className="footer-menu-title">Scaleteam</h4>
                                <div>
                                    <ul className="menu-list">
                                        <li><a href="#" className="footer-menu">Home</a></li>
                                        <li><a href="/about" className="footer-menu">About Us</a></li>
                                        <li><a href="/our-work" className="footer-menu">Our Work</a></li>
                                        <li><a href="/career" className="footer-menu">Career</a></li>
                                        <li><a href="/contact" className="footer-menu">Contact Us</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6 mt-sm-0 mt-4">
                                <h4 className="footer-menu-title">Services</h4>
                                <div>
                                    <ul className="menu-list">
                                        <li><a href="#" className="footer-menu">Website Development</a></li>
                                        <li><a href="#" className="footer-menu">Software Development</a></li>
                                        <li><a href="#" className="footer-menu">E-commerce Solutions</a></li>
                                        <li><a href="#" className="footer-menu">Healthcare Solutions</a></li>
                                        <li><a href="#" className="footer-menu">ERP System</a></li>
                                        <li><a href="#" className="footer-menu">CRM System</a></li>
                                        <li><a href="#" className="footer-menu">Logistics Solutions</a></li>
                                        <li><a href="#" className="footer-menu">Mobile Apps</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-5 pl-md-5 mt-md-0 mt-4">
                                <h4 className="footer-menu-title">HIRE DEVELOPERS</h4>
                                <div>
                                    <ul className="menu-list">
                                        <li><a href="#" className="footer-menu">React Developers</a></li>
                                        <li><a href="#" className="footer-menu">React Native Developers</a></li>
                                        <li><a href="#" className="footer-menu">Angular Developers</a></li>
                                        <li><a href="#" className="footer-menu">Nodejs Developers</a></li>
                                        <li><a href="#" className="footer-menu">iOS Developers</a></li>
                                        <li><a href="#" className="footer-menu">Android Developers</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 mt-lg-0 mt-4">
                        <h4 className="footer-menu-title">We’re here to help</h4>
                        <div className="row">
                            <div className="col-sm-5">
                                <div>
                                    <div className="footer-icon mt-1">
                                        <img src={Images.location} alt="" />
                                    </div>
                                    <h6 className="footer-address-title">Contact us</h6>
                                    <p className="address-contain">301-302, Amora Arcade, Patidar Chowk, near Mauni
                                    International School, Uttran, Surat, Gujarat 394105</p>
                                </div>
                            </div>
                            <div className="col-sm-7">
                                <div className="hr-department mt-md-0 mt-4">
                                    <div className="footer-icon mt-1">
                                        <img src={Images.call} alt="" />
                                    </div>
                                    <h6 className="footer-address-title">HR Department</h6>
                                    <p className="address-contain">+91 6262903090</p>
                                </div>
                                <div className="career-sales">
                                    <div className="footer-icon mt-1">
                                        <img src={Images.email} alt="" />
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <h6 className="footer-address-title" style={{ width: 70 }}> Career: </h6>
                                        <p className="address-contain"> hr@scale-team.com</p>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <h6 className="footer-address-title" style={{ width: 70 }}> Sales: </h6>
                                        <p className="address-contain"> business@scale-team.com</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row news-letter-row">
                            <div className="col-lg-9 col-md-6">
                                <div className="news-letter-data">
                                    <div className="input-email">
                                        <input className="email-send-input" type="email" placeholder="Email address…" />
                                    </div>
                                    <div className="email-icon">
                                        <img src={Images.paper} alt="" />
                                    </div>
                                </div>
                                <p className="footer-menu">We’ll reach out over email to set up a call.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    {/* <div className="copy-right"> */}
                    <div className="col-lg-4 col-sm-6 text-center">
                        <a href="" className="d-inline-block"><img src={Images.ftrlogo} alt="" /></a>
                    </div>
                    <div className="col-lg-4 col-sm-6 mt-md-0 mt-4">
                        <p className="copy-right-text text-center">Copyright© 2022, All Rights Reserved</p>
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <ul
                            className="share-icon d-flex align-items-center justify-content-lg-end mt-lg-0 mt-4 justify-content-center">
                            <li><a href="https://www.facebook.com/scaleteamtech/" target="_blank"><img src={Images.facebook} alt="" /></a></li>
                            <li><a href="https://instagram.com/scaleteam?utm_medium=copy_link" target="_blank"><img src={Images.insta} alt="" /></a></li>
                            <li><a href="#"><img src={Images.twitter} alt="" /></a></li>
                            <li><a href="https://www.linkedin.com/company/scaleteam/mycompany/" target="_blank"><img src={Images.linked} alt="" /></a></li>
                        </ul>
                    </div>
                    {/* </div> */}
                </div>
            </div>
        </footer>
    )
}