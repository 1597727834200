import {gql} from '@apollo/client'

export const allOpinions = gql`
query{
  opinionsST {
  id
    longDescription
    title
    shortDescription
    color {
      hex
    }
  }
}

`