import { Header } from "../Components/Common/Header"
import { Footer } from "../Components/Common/Footer"

export const Wrapper = (props) => {
    return (
        <div className="scaleteam">
            <Header />
            {props?.children}
            <Footer />
        </div>
    )
}